<template>
    <div class="page">
        <div id="root-nav">
            <div class="inner-wrap">
                <div class="logo">Sync Scan</div>

                <div class="navbar-items">
                    <slot v-if="!$isPropTruthy(noNav)" name="nav">
                        <router-link to="/dashboard">Dashboard</router-link>
                        <router-link to="/devices">Devices</router-link>
                        <router-link to="/people">People</router-link>
                        <router-link to="/reports">Reports</router-link>
                        <router-link to="/settings">Settings</router-link>
                        <router-link v-if="canSysAdmin" to="/sysadmin">System Admin</router-link>
                    </slot>
                </div>

                <div class="user">
                    <div class="menu-text">
                        <div class="user-info">
                            <div class="user-name">{{ session.firstName }} {{ session.lastName }}</div>
                            <div class="org-name">{{ session.activeOrg.name }}</div>
                        </div>
                        <i class="fa fa-caret-down"></i>
                    </div>
                    <div class="dropdown">
                        <a v-if="canSwitchOrgs" href="#" @click.prevent="switchOrg">Switch Organization</a>
                        <a href="#" @click.prevent="changePassword">Change Password</a>
                        <router-link to="/logout">Sign out</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="shouldShowCloudConnectionError" id="top-error">
            <div class="inner-wrap">
                This server is not connected to the Sync Scan cloud connector. E-mails and text messages will not be sent until the connection has
                been re-established.
            </div>
        </div>

        <div id="root-content" v-infinite-scroll="infiniteScrollHandler">
            <div class="inner-wrap">
                <slot class="content" />
            </div>
        </div>
    </div>
</template>

<script>
import SwitchOrgModal from './page/m-switch-org';
import ChangePasswordModal from './page/m-change-password';

export default {
    props: ['noNav', 'infiniteScrollHandler'],
    store: ['session'],

    computed: {
        canSysAdmin() {
            return (this.session.isSuperUser || this.session.isOrgAdmin) && this.session.activeOrg.isReseller;
        },

        canSwitchOrgs() {
            return this.session.isCloudServer && (this.session.isSuperUser || this.session.orgs.length > 1);
        },

        shouldShowCloudConnectionError() {
            return this.session.isCloudConnectorConnected === false && this.session.isServerConfigured === true;
        }
    },

    methods: {
        switchOrg() {
            this.$modal(SwitchOrgModal);
        },

        changePassword() {
            this.$modal(ChangePasswordModal);
        }
    }
};
</script>

<style lang="scss">
.page {
    @apply min-h-0;

    .inner-wrap {
        @apply mx-auto max-w-screen-xl flex-1 flex;
    }

    #root-nav {
        @apply flex text-white;
        background: #2d3436;
        z-index: 1;

        .logo {
            display: inline-block;
            font-size: 0;
            width: 100px;
            height: 43px;
            background: url('~@/assets/images/logos/synccity-white.svg') no-repeat;
            background-size: contain;
            margin: 6px;
            margin-right: 18px;
        }

        .navbar-items {
            @apply flex-1 flex;

            a {
                @apply block no-underline text-white py-0 px-4 text-lg;
                line-height: 55px;

                &.router-link-active {
                    background: #636e72;
                }

                &:hover {
                    background: lighten(#636e72, 10%);
                }
            }
        }

        .user {
            @apply relative flex items-center p-2;

            .menu-text {
                @apply flex items-center cursor-pointer;

                .user-name {
                    @apply text-xl;
                    line-height: 15px;
                }

                .org-name {
                    @apply text-base mt-1;
                    line-height: 12px;
                    color: #ddd;
                }

                .user-info {
                    @apply mr-2;
                }
            }

            .dropdown {
                @apply absolute hidden right-0 rounded-b-md overflow-hidden;
                background: lighten(#343a40, 10%);
                top: 100%;
                width: 175px;

                a {
                    @apply block text-white p-2 no-underline text-lg;

                    &:hover {
                        background: lighten(#343a40, 20%);
                    }
                }
            }

            &:hover {
                .dropdown {
                    @apply block;
                }
            }
        }
    }

    #top-error {
        @apply p-2 bg-red-700 text-white;

        .inner-wrap {
            @apply justify-center text-lg;
        }
    }

    #root-content {
        @apply flex-1 overflow-auto;
        background: #f6f8f8;

        > .inner-wrap {
            @apply flex-col py-4 min-h-0;
        }
    }
}
</style>
