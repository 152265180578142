<template>
    <form v-if="!reportData">
        <p>Please upload the employee schedule workbook.</p>
        <p><input ref="fileInput" type="file" accept=".xlsx" /></p>
        <p><button class="btn-primary" @click.prevent="generate">Upload &amp; Generate Report</button></p>
    </form>

    <table v-else>
        <thead>
            <th>Person</th>
            <th v-for="date in reportData.dates" :key="date" v-datetime="date" format="MM/DD/YY" local />
        </thead>
        <tbody>
            <tr v-for="(person, index) in reportData.people" :key="index">
                <td>{{ person.lastName }}, {{ person.firstName }}</td>
                <td v-if="person.notFound" :colspan="reportData.dates.length" class="not-found">Not found in SyncScan</td>
                <td v-else v-for="date in reportData.dates" :key="index + ':' + date" :class="[person.dates[date] && person.dates[date].a]">
                    <template v-if="!person.dates[date]">-</template>
                    <template v-else-if="person.dates[date].a === 'missing'">Missing</template>
                    <template v-else>
                        <span class="mr-2">{{ person.dates[date].t || '-' }}</span>

                        <template v-if="person.dates[date].q">
                            <i
                                v-for="(wqId, index) in person.dates[date].q"
                                :key="index"
                                class="fas fa-comment-edit cursor-pointer hover:text-gray-500"
                                @click="showWQ(wqId)"
                            />
                        </template>
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import WQViewModal from '@/common/modals/wq-view';

export default {
    data() {
        return {
            scheduleFile: null,
            reportData: null
        };
    },

    methods: {
        async generate() {
            try {
                if (!this.$refs.fileInput.files.length) this.$throwUserError('Please select a file.');

                this.$wait();

                const fd = new FormData();
                fd.append('file', this.$refs.fileInput.files[0]);

                const response = await this.$http.post('/api/orgs/:orgId/reports/custom/pf1', fd);
                this.reportData = response.data;
            } catch (err) {
                this.$alert('Upload & Generate Report', err);
            } finally {
                this.$endWait && this.$endWait();
            }
        },

        showWQ(wqId) {
            this.$modal(WQViewModal, { wqId });
        }
    }
};
</script>

<style lang="scss" scoped>
p {
    @apply my-4;
}

.missing {
    @apply text-red-700 italic;
}

.not-found {
    @apply text-gray-500 italic text-center;
}

.unexpected {
    @apply text-yellow-600 font-bold;
}
</style>
