<template>
    <modal id="edit-user">
        <h1 slot="header">{{ title }}</h1>

        <div class="input">
            <label>Name</label>
            <div class="name">
                <input v-model="user.firstName" type="text" placeholder="first" required v-disabled="user.id" v-autofocus />
                <input v-model="user.lastName" type="text" placeholder="last" required v-disabled="user.id" />
            </div>
        </div>

        <div class="input">
            <label>E-mail</label>
            <input v-model="user.email" type="email" required v-disabled="user.id" />
        </div>

        <label class="admin"> <input v-model="user.isOrgAdmin" type="checkbox" /> Grant administrative rights</label>

        <div slot="footer">
            <button class="btn-primary" @click.prevent="save">Save</button>
            <button class="btn-default" @click.prevent="$dismiss()">Cancel</button>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            user: {
                firstName: null,
                lastName: null,
                email: null,
                isOrgAdmin: false
            }
        };
    },

    computed: {
        title() {
            return this.org ? 'Edit User' : 'Add User';
        }
    },

    methods: {
        async save() {
            try {
                this.$maskForm('.btn-primary');

                let response;

                if (this.user.id) {
                    response = await this.$http.put('/api/orgs/:orgId/users/' + this.user.id, {
                        isOrgAdmin: this.user.isOrgAdmin
                    });
                } else {
                    response = await this.$http.post('/api/orgs/:orgId/users', {
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        email: this.user.email,
                        isOrgAdmin: this.user.isOrgAdmin
                    });
                }

                if (response.data.generatedPassword) {
                    await this.$alert(
                        'Add User',
                        'An initial password was generated for ' +
                            response.data.firstName +
                            '. Please copy it now, as it will not be displayed again:\n\n' +
                            response.data.generatedPassword
                    );
                    delete response.data.generatedPassword;
                }

                this.$dismiss(response.data);
            } catch (err) {
                this.$unmaskForm();
                this.$alert(this.title, err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vf-modal {
        min-width: 200px;
    }

    .vf-modal-content {
        @apply p-2;
    }
}

.name {
    @apply flex;

    input {
        @apply flex-1;

        &:not(:first-child) {
            @apply ml-1;
        }
    }
}

.input {
    &:not(:first-child) {
        @apply mt-4;
    }

    label {
        @apply mb-1;
    }

    input {
        @apply block w-full;
    }
}

label.admin {
    @apply flex items-center mt-4;

    input {
        @apply mr-2;
    }
}
</style>
