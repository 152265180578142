<template>
    <div class="photos" ref="uploadScroller">
        <div v-if="uploads.length" class="uploads">
            <div v-for="(upload, index) in uploads" :key="index" class="upload">
                <div class="photo">
                    <img v-if="upload.previewData" :src="upload.previewData" />
                    <div v-else class="placeholder">
                        <i class="fas fa-spinner fa-spin"></i>
                    </div>
                </div>

                <div class="info">
                    <div class="identifier">{{ upload.personIdentifier }}</div>

                    <div v-if="upload.status === null" class="status pending">Pending</div>
                    <div v-else-if="typeof upload.status === 'object'" class="status error">{{ upload.status.message }}</div>
                    <div v-else-if="upload.status === 'uploading'" class="status uploading">Uploading...</div>
                    <div v-else-if="upload.status === 'done'" class="status done">Uploaded</div>
                </div>
            </div>
        </div>

        <div v-else class="instructions">
            <div>
                Drag &amp; drop a folder or files to this browser window.<br /><br />
                Files should be named by the ID of the<br />person you wish to associate them to.<br /><br />
                JPEG is the only supported format.
            </div>
        </div>
    </div>
</template>

<script>
import reduce from 'image-blob-reduce';

export default {
    data() {
        return {
            uploads: [],
            previewQueue: [],
            isGeneratingPreview: false,
            uploadQueue: [],
            isUploading: false,
            uploadPendingCount: 0
        };
    },

    computed: {
        isReady() {
            return this.uploadPendingCount === 0;
        }
    },

    watch: {
        isReady() {
            this.$emit('ready', this.isReady);
        }
    },

    created() {
        this.previewReducer = reduce();

        window.addEventListener('dragover', this.handleDragOver);
        window.addEventListener('drop', this.handleDrop);
        this.$emit('ready', true);
    },

    beforeDestroy() {
        window.removeEventListener('dragover', this.handleDragOver);
        window.removeEventListener('drop', this.handleDrop);
    },

    methods: {
        handleDragOver(e) {
            e.preventDefault();
        },

        handleDrop(e) {
            e.preventDefault();

            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                let entry = e.dataTransfer.items[i].webkitGetAsEntry();
                if (!entry) return;
                if (entry.isFile) {
                    entry.file(file => this.addFile(file));
                } else if (entry.isDirectory) {
                    this.addFolder(entry);
                }
            }
        },

        async addFolder(dirEntry) {
            let reader = dirEntry.createReader();
            let entries = [];

            await new Promise(resolve => {
                let getEntries = () => {
                    reader.readEntries(results => {
                        if (!results.length) return resolve();
                        entries.push.apply(entries, results);
                        getEntries();
                    });
                };
                getEntries();
            });

            entries.forEach(entry => {
                entry.isFile && entry.file(file => this.addFile(file));
            });
        },

        addFile(fileObject) {
            if (fileObject.type !== 'image/jpeg') return;
            const personIdentifier = fileObject.name.replace(/\..+$/, '');

            let upload = {
                personIdentifier,
                status: null,
                previewData: null,
                file: fileObject
            };
            this.uploads.push(upload);

            this.previewQueue.push(upload);
            this.isGeneratingPreview || this.generateNextPreview();

            this.uploadQueue.push(upload);
            this.uploadPendingCount++;
            this.isUploading || this.uploadNextPhoto();
        },

        async generateNextPreview() {
            this.isGeneratingPreview = true;

            const queueEntry = this.previewQueue.shift();
            const previewBlob = await this.previewReducer.toBlob(queueEntry.file, { max: 96 });

            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                queueEntry.previewData = fileReader.result;

                if (this.previewQueue.length) this.$nextTick(this.generateNextPreview);
                else this.isGeneratingPreview = false;
            });
            fileReader.readAsDataURL(previewBlob);
        },

        uploadNextPhoto() {
            this.isUploading = true;
            this.$nextTick(this._uploadNextPhoto);
        },

        async _uploadNextPhoto() {
            const queueEntry = this.uploadQueue.shift();

            const uploadIndex = this.uploads.indexOf(queueEntry);

            const scrollBottom = this.$refs.uploadScroller.scrollTop + this.$refs.uploadScroller.clientHeight;

            const uploadEl = this.$refs.uploadScroller.querySelectorAll('.upload')[uploadIndex];
            const uploadElBottom = uploadEl.offsetTop + uploadEl.offsetHeight;

            if (uploadElBottom > scrollBottom) {
                this.$refs.uploadScroller.scrollTop = uploadElBottom - this.$refs.uploadScroller.clientHeight + 10;
            }

            try {
                queueEntry.status = 'uploading';

                let formData = new FormData();
                formData.set('photo', queueEntry.file);

                await this.$http.post('/api/orgs/:orgId/people/upload-photo?identifier=' + escape(queueEntry.personIdentifier), formData);

                queueEntry.status = 'done';
            } catch (err) {
                if (err.code === 'USERERR') queueEntry.status = err;
                else queueEntry.status = new Error('An unexpected problem occurred while uploading the photo.');
            }

            this.uploadPendingCount--;

            if (this.uploadQueue.length) this.$nextTick(this._uploadNextPhoto);
            else this.isUploading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
div.photos {
    width: 550px;
    @apply flex-1 overflow-auto relative;
}

.uploads {
    @apply p-2;

    > div {
        @apply flex;

        &:not(:first-child) {
            @apply mt-2;
        }
    }

    .photo {
        @apply relative rounded-md;
        width: 48px;
        height: 48px;

        .placeholder {
            @apply absolute rounded-md w-full h-full flex items-center justify-center;
            background: #e2e8f0;
            color: #666;

            i {
                @apply text-3xl;
            }
        }

        img {
            @apply absolute object-cover rounded-md;
            width: 48px;
            height: 48px;
            z-index: 1;
        }
    }

    .info {
        @apply ml-2 flex flex-col justify-center;

        .status.pending,
        .status.done {
            @apply text-gray-500;
        }

        .status.error {
            @apply text-red-700;
        }
    }
}

.instructions {
    @apply h-full flex items-center justify-center;

    div {
        @apply text-gray-600 text-lg text-center;
    }
}
</style>
