<template>
    <div>
        <div class="filters flex">
            <!-- TODO: fix v-tip on buttons -->
            <button
                class="btn-primary"
                @click="addUser"
                v-disabled="!users || (!session.isProProduct && users.length >= 1)"
                v-tip="!session.isProProduct && 'This is a Pro feature. Please contact your sales representative for more information.'"
            >
                <i class="fa fa-plus" /> Add User
            </button>
        </div>

        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Admin</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!users" class="info">
                    <td colspan="3">Loading...</td>
                </tr>
                <tr v-else-if="users.length == 0" class="info">
                    <td colspan="3">No users have been created for this organization.</td>
                </tr>
                <tr
                    v-else
                    v-for="user in users"
                    :key="user.id"
                    class="clickable"
                    @click="editUser(user)"
                    @contextmenu.prevent="showContextMenu(user, $event)"
                >
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                        <i v-if="user.isOrgAdmin" class="fa fa-check" />
                        <template v-else>-</template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import EditUserModal from './f-users/m-edit';

export default {
    store: ['session'],

    data() {
        return {
            users: null
        };
    },

    watch: {
        session: {
            deep: true,
            handler() {
                this.load();
            }
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.users = null;
            const response = await this.$http.get('/api/orgs/:orgId/users');
            this.users = response.data;
        },

        async addUser() {
            const user = await this.$modal(EditUserModal);
            if (!user) return;
            this.users.unshift(user);
        },

        async editUser(user) {
            const updatedUser = await this.$modal(EditUserModal, { user });
            if (!updatedUser) return;
            Object.assign(user, updatedUser);
        },

        async deleteUser(user) {
            const response = await this.$confirm('Delete User', 'Are you sure you want to delete ' + user.firstName + ' ' + user.lastName + '?');
            if (!response) return;

            this.$wait();
            await this.$http.delete('/api/orgs/:orgId/users/' + user.id);
            this.$endWait();

            this.users.remove(user);
        },

        async showContextMenu(user, e) {
            this.$contextMenu(e, {
                items: [
                    {
                        title: 'Edit',
                        handler: () => this.editUser(user)
                    },
                    {
                        title: 'Delete',
                        handler: () => this.deleteUser(user)
                    }
                ]
            });
        }
    }
};
</script>

<style></style>
