<template>
    <div class="name">
        <template v-if="isEditing">
            <div class="editor">
                <input v-model="name" type="text" placeholder="device name" v-autofocus v-disabled="isSaving" />
                <i v-if="isSaving" class="fa fa-spinner fa-spin" />
                <template v-else>
                    <i class="fa fa-times absolute" @click="cancel" />
                    <i class="fa fa-check absolute" @click="save" />
                </template>
            </div>
        </template>
        <span v-else>
            {{ device.name }}
            <i class="fas fa-pencil ml-4 cursor-pointer" @click="edit" />
        </span>
    </div>
</template>

<script>
export default {
    props: ['device'],

    data() {
        return {
            isEditing: false,
            isSaving: false,
            name: null
        };
    },

    created() {
        this.name = this.device.name;
    },

    methods: {
        edit() {
            this.isEditing = true;
        },

        cancel() {
            this.name = this.device.name;
            this.isEditing = false;
        },

        async save() {
            try {
                this.isSaving = true;

                await this.$http.put('/api/orgs/:orgId/devices/' + this.device.mac, {
                    name: this.name
                });

                this.isSaving = false;
                this.isEditing = false;
                this.$emit('name-updated', this.name);
            } catch (err) {
                this.isSaving = false;
                this.$alert('Edit Device', err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
span {
    @apply flex items-center ml-2 text-2xl;
    line-height: 34px;

    i {
        @apply invisible text-gray-500;

        &:hover {
            @apply text-gray-900;
        }
    }

    &:hover i {
        @apply visible;
    }
}

.editor {
    @apply relative flex items-center;

    input {
        @apply text-2xl pr-16;
        min-width: 3.5rem;
    }

    i {
        @apply absolute text-xl cursor-pointer;

        &:first-of-type {
            right: 0.5rem;
        }

        &:nth-of-type(2) {
            right: 2rem;
        }
    }
}
</style>
