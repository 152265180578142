<template>
    <div id="login">
        <form @submit.prevent="login">
            <div class="logo"></div>

            <div v-if="isLoading" class="loading">Please wait...</div>

            <template v-else>
                <div v-if="error" class="error">
                    {{ error }}
                </div>
                <input v-model="email" ref="email" type="email" placeholder="email address" required v-autofocus />
                <input v-model="password" ref="password" type="password" placeholder="password" required />
                <!-- <div>
                    <label><input type="checkbox" v-model="shouldRememberMe" /> Remember me on this computer</label>
                </div> -->
                <button class="btn-primary">Sign in</button>
            </template>
        </form>
    </div>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            isLoading: true,
            error: null,
            email: null,
            password: null,
            shouldRememberMe: false
        };
    },

    async mounted() {
        try {
            const response = await this.$http.get('/api/me');
            this.$app.setSession(response.data);
        } catch (err) {
            // if we're not authenticated, we'll just show the login
            if (err.response && err.response.status == 401) {
                this.isLoading = false;
                return;
            }

            // otherwise, show an error
            this.$reportError(err);
            this.$app.globalError = 'There was an error initializing the application. Please try again.';
        }
    },

    methods: {
        async login() {
            this.$maskForm('.btn-primary');

            try {
                const response = await this.$http.post('/api/login', {
                    email: this.email,
                    password: this.password
                });

                if (!response.data.activeOrg) {
                    this.error = 'The user is not associated with any organization. Please contact support.';
                    return;
                }

                this.$app.setSession(response.data);
            } catch (err) {
                this.error = err.code == 'USERERR' ? err.message : 'An unexpected error was encountered. Please try again.';

                if (this.error.includes('not registered')) this.$refs.email.focus();
                else if (this.error.includes('password')) this.$refs.password.focus();
            } finally {
                this.$unmaskForm();
            }
        }
    }
};
</script>

<style lang="scss">
#login {
    @apply flex flex-col justify-center items-center;

    form {
        @apply rounded-md p-8;
        background: #f6f6f6;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        width: 325px;

        .logo {
            background: url('~@/assets/images/logos/synccity-black.svg');
            @apply mx-auto mb-8 bg-contain;
            width: 200px;
            height: 112px;
        }

        .loading {
            @apply text-center text-lg py-16;
            padding: 48px 0;
        }

        .error {
            @apply my-2 mx-0;
            color: #c00;
        }

        input[type='email'],
        input[type='password'],
        button {
            @apply w-full my-1;
        }
    }
}
</style>
