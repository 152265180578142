<template>
    <modal id="edit-organization" @form-submit="save" scrolls>
        <h1 slot="header">{{ title }}</h1>

        <div class="flex">
            <div class="input">
                <label>Name</label>
                <input v-model="org.name" type="text" required v-autofocus />
            </div>

            <div class="input">
                <label>Type</label>
                <div class="select-wrap">
                    <select v-model="org.type" v-disabled="org.uuid">
                        <option value="cloud">Cloud</option>
                        <option v-if="isOrgSync" value="onprem">On-Premises</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="input">
            <table class="licenses">
                <thead>
                    <tr>
                        <th colspan="20" class="licenses">
                            Licenses
                            <div class="add-wrap" @click="addLicense">
                                <i class="fa fa-plus"></i>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Active</th>
                        <th>Product</th>
                        <th>Devices</th>
                        <th>Start</th>
                        <th>End</th>
                        <template v-if="isOrgSync">
                            <th v-if="isOrgSync">Reseller</th>
                            <th>Sync $$</th>
                            <th>Sync Pd</th>
                            <th>S24 Invcd</th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="!org.licenses || !org.licenses.length" class="info">
                        <td colspan="20">No licenses have been added.</td>
                    </tr>
                    <tr v-else v-for="(license, index) in org.licenses" :key="index">
                        <td>
                            <input
                                v-model="license.isActive"
                                v-disabled="license.resellerUuid !== session.activeOrg.uuid && !isOrgSync"
                                type="checkbox"
                                @change="license.isActive || license.id || org.licenses.remove(license)"
                            />
                        </td>
                        <td>
                            <div class="select-wrap">
                                <select v-model="license.product" v-disabled="license.id">
                                    <option value="pro">Pro</option>
                                    <option value="lite">Lite</option>
                                </select>
                            </div>
                        </td>
                        <td>
                            <input v-model.number="license.deviceCount" type="number" min="1" step="1" class="device-count" v-disabled="license.id" />
                        </td>
                        <td>
                            <input v-model="license.dateStart" type="date" v-disabled="license.id" />
                        </td>
                        <td>
                            <input v-model="license.dateEnd" type="date" v-disabled="license.id" />
                        </td>

                        <template v-if="isOrgSync">
                            <td>
                                <div class="select-wrap">
                                    <select v-model="license.resellerUuid">
                                        <option v-for="reseller in resellers" :key="reseller.id" :value="reseller.uuid">{{ reseller.name }}</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <input v-model.number="license.revenue" type="number" min="0" step="0.01" class="revenue" />
                            </td>
                            <td>
                                <input v-model="license.hasPaidDistributor" type="checkbox" />
                            </td>
                            <td>
                                <input v-model="license.hasPaidS24" v-disabled="!isUserS24" type="checkbox" />
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>

        <div slot="footer">
            <button class="btn-primary">Save</button>
            <button class="btn-default" @click.prevent="$dismiss()">Cancel</button>
        </div>
    </modal>
</template>

<script>
import moment from 'moment';

export default {
    store: ['session'],

    data() {
        return {
            org: {
                uuid: null,
                name: null,
                type: 'cloud',
                licenses: []
            }
        };
    },

    computed: {
        title() {
            return this.org ? 'Edit Organization' : 'Add Organization';
        },

        resellers() {
            return this.$modalOpener.resellers;
        },

        isOrgSync() {
            return this.$modalOpener.isOrgSync;
        },

        isUserS24() {
            return /@sgnl24\.com$/.test(this.session.email);
        }
    },

    mounted() {
        if (this.org.uuid) this.load();
        else this.addLicense();
    },

    methods: {
        async load() {
            try {
                this.$wait();
                const response = await this.$http.get('/api/orgs/:orgId/sysadmin/orgs/' + this.org.uuid);
                this.org = response.data;
            } catch (err) {
                this.$alert('Configuration', err);
                this.$dismiss();
            } finally {
                this.$endWait();
            }
        },

        addLicense() {
            this.org.licenses.push({
                isActive: true,
                product: 'pro',
                deviceCount: 1,
                dateStart: moment().format('YYYY-MM-DD'),
                dateEnd: moment().add(1, 'year').format('YYYY-MM-DD'),
                resellerUuid: this.session.activeOrg.uuid,
                revenue: null,
                hasPaidDistributor: false,
                hasPaidS24: false
            });
        },

        async save() {
            this.$maskForm('.btn-primary');

            try {
                const method = this.org.uuid ? 'put' : 'post';
                const url = this.org.uuid ? '/api/orgs/:orgId/sysadmin/orgs/' + this.org.uuid : '/api/orgs/:orgId/sysadmin/orgs';
                const result = await this.$http[method](url, this.org);
                this.$dismiss(result.data);
            } catch (err) {
                this.$unmaskForm();
                this.$alert(this.title, err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vf-modal {
        min-width: 300px;
    }

    .vf-modal-content {
        @apply p-2;
    }
}

.input {
    @apply flex-1 flex flex-col m-2;

    label {
        @apply mb-1;
    }

    input {
        @apply w-full;
    }

    &.required label {
        font-weight: bold;
    }
}

th.licenses {
    @apply text-center p-2 bg-gray-800 relative;

    .add-wrap {
        @apply absolute right-0 top-0 h-full p-3 flex justify-center items-center cursor-pointer hover:text-gray-300;
    }
}

input.device-count {
    width: 50px;
}

input[type='date'] {
    width: 125px;
}

input.revenue {
    width: 75px;
}
</style>
