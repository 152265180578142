import XLSX from 'xlsx';

export default function tableToExcel(fileName, sheetName, tableEl) {
    let dataArray = [[]];
    let maxLens = [];

    tableEl
        .querySelector('thead')
        .querySelectorAll('th')
        .forEach((th, index) => {
            const text = th.innerText;
            dataArray[0].push(text);
            maxLens[index] = text.length;
        });

    tableEl
        .querySelector('tbody')
        .querySelectorAll('tr')
        .forEach(tr => {
            let trOut = [];
            tr.querySelectorAll('td').forEach((td, index) => {
                const text = td.innerText;
                trOut.push(text);
                maxLens[index] = Math.max(maxLens[index], text.length);
            });
            dataArray.push(trOut);
        });

    const workbook = XLSX.utils.book_new();

    let sheet = XLSX.utils.aoa_to_sheet(dataArray);
    sheet['!cols'] = maxLens.map(len => {
        return { wch: len + 1 };
    });
    XLSX.utils.book_append_sheet(workbook, sheet, sheetName);

    XLSX.writeFile(workbook, fileName);
}
