<template>
    <div id="alert-settings">
        <div v-if="!session.isProProduct" class="pro-alert">
            The below options are Pro features.<br />Please contact your sales representative for more information.
        </div>

        <div class="container relative" :class="{ disabled: !session.isProProduct }">
            <div class="setting">
                <span class="font-bold">Receive alert over temperature threshold</span>

                <div class="inputs-container">
                    <input
                        v-if="settings.shouldAlertOverThreshold"
                        v-model.number="settings.tempUpperLimitAlert"
                        type="number"
                        step="0.1"
                        maxlength="5"
                        v-disabled="!session.isProProduct"
                    />

                    <label class="toggle">
                        <input type="checkbox" v-model="settings.shouldAlertOverThreshold" v-disabled="!session.isProProduct" />
                        <div />
                    </label>
                </div>
            </div>

            <div class="setting">
                <span class="font-bold">Receive alert upon attempted entry from banned persons</span>

                <label class="toggle ml-auto">
                    <input type="checkbox" v-model="settings.shouldAlertOnBannedAttempt" v-disabled="!session.isProProduct" />
                    <div />
                </label>
            </div>

            <div class="alert-methods">
                <span class="block font-bold my-2">Alert destinations</span>

                <div v-for="(entry, index) in settings.alertDestinations" :key="index" class="row">
                    <div class="select-wrap">
                        <select v-model="entry.type">
                            <option value="phone">Phone</option>
                            <option value="email">E-mail</option>
                        </select>
                    </div>

                    <input
                        v-if="entry.type === 'phone'"
                        v-model="entry.address"
                        type="tel"
                        placeholder="(###) ###-####"
                        v-autofocus="!entry.address"
                    />
                    <input v-else v-model="entry.address" type="email" placeholder="______@_____.____" v-autofocus="!entry.address" />

                    <i class="fa fa-times remove" @click="removeAlertDestination(entry)" />
                </div>

                <a href="#" @click.prevent="addAlertDestination">+ Add another</a>
            </div>

            <div class="flex">
                <button :disabled="!haveSettingsChanged" class="btn-primary ml-auto" @click="save">Save Changes</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            haveSettingsChanged: false,

            settings: {
                shouldAlertOverThreshold: null,
                tempUpperLimitAlert: null,
                shouldAlertOnBannedAttempt: null,
                alertDestinations: []
            }
        };
    },

    watch: {
        session: {
            deep: true,
            handler() {
                this.load();
            }
        },

        settings: {
            deep: true,
            handler() {
                this.haveSettingsChanged = true;
            }
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.$wait();

            const response = await this.$http.get('/api/orgs/:orgId/config?keys=' + Object.keys(this.settings).join(','));
            this.settings = response.data;

            this.$endWait();

            this.$nextTick(() => {
                this.haveSettingsChanged = false;
            });
        },

        addAlertDestination() {
            if (!this.session.isProProduct) return;
            this.settings.alertDestinations.push({ type: 'phone', address: '' });
        },

        removeAlertDestination(entry) {
            this.settings.alertDestinations.remove(entry);
        },

        async save() {
            for (let i = 0; i < this.settings.alertDestinations.length; i++) {
                if (!this.settings.alertDestinations[i].address) {
                    this.settings.alertDestinations.splice(i, 1);
                    continue;
                }

                if (this.settings.alertDestinations[i].type === 'email') {
                    if (!/^.+@.+\..+$/.test(this.settings.alertDestinations[i].address))
                        return this.$alert('Save', '"' + this.settings.alertDestinations[i].address + '" is not a valid email.');
                } else if (this.settings.alertDestinations[i].type === 'phone') {
                    let phone = this.settings.alertDestinations[i].address;
                    phone = phone.replace(/[^0-9]/g, '');
                    if (phone.length === 11 && phone.substr(0, 1) === '1') phone = phone.substr(1);
                    if (phone.length !== 10)
                        return this.$alert(
                            'Save',
                            '"' + this.settings.alertDestinations[i].address + '" is not a valid phone. Please enter a 10-digit US phone number.'
                        );
                    this.settings.alertDestinations[i].address = phone;
                }
            }

            if (!this.settings.tempUpperLimitAlert) {
                this.settings.tempUpperLimitAlert = 100.5;
            }

            this.$wait();

            try {
                await this.$http.post('/api/orgs/:orgId/config', this.settings);
                this.haveSettingsChanged = false;
            } catch (err) {
                this.$alert('Save Changes', err);
            } finally {
                this.$endWait();
            }
        }
    }
};
</script>

<style lang="scss">
#alert-settings {
    .pro-alert {
        @apply text-center text-lg p-12 font-bold;
    }

    .disabled {
        opacity: 0.5;
    }

    .container {
        @apply max-w-4xl mx-auto;
    }

    .setting {
        @apply flex items-center justify-center flex-wrap bg-white mb-4 p-4 rounded-md shadow-sm;
        min-height: 60px;

        .inputs-container {
            @apply flex ml-auto items-center;
            min-height: 34px;

            input {
                @apply relative mr-2;
                width: 46px;
            }
        }
    }

    .alert-methods {
        @apply flex-col bg-white rounded-md shadow-sm mb-4 p-4;

        .row {
            @apply flex items-center my-4;

            select {
                @apply pr-8;
            }

            input[type='tel'],
            input[type='email'] {
                @apply ml-2 w-1/3;
            }

            .remove {
                @apply ml-2 text-gray-500 hidden cursor-pointer;

                &:hover {
                    @apply text-gray-800;
                }
            }

            &:hover {
                .remove {
                    display: inherit;
                }
            }
        }

        a {
            color: $button-primary-color;
        }
    }
}
</style>
