<template>
    <div id="server-settings">
        <form>
            <label>Software Versions</label>
            <dl>
                <dt>Server (Installed)</dt>
                <dd>{{ swVersionServerCurrent | version | dash }}</dd>
                <dt>Server (Latest)</dt>
                <dd>{{ swVersionServerLatest | version | dash }}</dd>
                <dt>Device (Latest)</dt>
                <dd>{{ swVersionDeviceLatest | version | dash }}</dd>
            </dl>

            <button v-if="isServerUpdating" class="btn-primary update-btn" disabled>Installing update...</button>
            <button v-else-if="isServerUpdateAvailable" class="btn-primary update-btn" @click.prevent="updateServer">Update server now</button>
        </form>

        <form>
            <label>License</label>
            <dl>
                <dt>Licensed Devices</dt>
                <dd>{{ licensedDeviceCount | dash }}</dd>
                <dt>Attached Devices</dt>
                <dd>{{ attachedDeviceCount | dash }}</dd>
                <dt>License Expiration</dt>
                <dd v-datetime="licenseExpiration" format="YYYY-MM-DD" />
            </dl>
        </form>

        <form>
            <label>Organization</label>
            <input v-model="orgName" type="text" class="org" placeholder="Acme Corporation" required />
            <button class="btn-primary" @click.prevent="saveOrg">Save</button>
            <p>This name will appear on the kiosks and in alert and enrollment request e-mails and texts.</p>
        </form>

        <form>
            <label>Server IP Address</label>
            <input v-model="serverIp" type="text" placeholder="1.2.3.4" required />
            <button class="btn-primary" @click.prevent="saveIp">Save</button>
            <p>This is the IP kiosks will use to connect to your server. Ensure it's accessible from the subnet the kiosks are attached to.</p>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            swVersionServerCurrent: null,
            swVersionServerLatest: null,
            swVersionDeviceLatest: null,
            isServerUpdateAvailable: null,
            isServerUpdating: null,
            orgName: null,
            serverIp: null,
            licensedDeviceCount: null,
            attachedDeviceCount: null,
            licenseExpiration: null
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.$wait();

            const response = await this.$http.get('/api/setup');
            Object.assign(this, response.data);

            this.$endWait();
        },

        async updateServer() {
            this.isServerUpdating = true;

            this.$wait();

            try {
                await this.$http.post('/api/setup/install-update');
            } catch (err) {
                this.isServerUpdating = false;
                this.$endWait();
                this.$alert('Update Server', err);
                return;
            }

            await this.$alert('Update Server', 'Update has been installed successfully.');
            location.reload();
        },

        async saveOrg() {
            if (!this.orgName) return;

            try {
                this.$wait();
                await this.$http.put('/api/setup', {
                    orgName: this.orgName
                });
            } catch (err) {
                this.$alert('Save', err);
            } finally {
                this.$endWait();
            }
        },

        async saveIp() {
            if (!this.serverIp) return;

            try {
                this.$wait();
                await this.$http.put('/api/setup', {
                    serverIp: this.serverIp
                });
            } catch (err) {
                this.$alert('Save', err);
            } finally {
                this.$endWait();
            }
        }
    }
};
</script>

<style lang="scss">
#server-settings {
    form {
        &:not(:first-child) {
            @apply mt-8;
        }
    }

    label {
        @apply block font-bold underline;
    }

    dl {
        dt {
            @apply float-left font-bold w-40 mr-4;
        }
    }

    button {
        @apply ml-1;
    }

    p {
        @apply mt-1 text-gray-500;
    }

    .update-btn {
        @apply mt-2 ml-0;
    }
}
</style>
