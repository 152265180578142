<template>
    <tr :class="{ 'no-hover': isEditing }">
        <td>
            <template v-if="isEditing">
                <input v-model="name" type="text" v-disabled="isSaving" v-autofocus @keydown.enter.prevent="save" />

                <div class="controls">
                    <template v-if="!isSaving">
                        <i class="fa fa-times ml-2" @click="cancelEditing" />
                        <i class="fa fa-check ml-2" @click="save" />
                    </template>

                    <i v-else class="fad fa-spinner-third fa-spin" style="--fa-secondary-opacity: 0.15" />
                </div>
            </template>

            <template v-else>
                <span class="group-name">
                    {{ group.name }}
                    <i class="fa fa-pencil" @click="edit" />
                </span>

                <button v-if="group" class="btn-destroy" @click="deleteGroup">
                    <i class="fa fa-trash-alt" />
                </button>
            </template>
        </td>
    </tr>
</template>

<script>
export default {
    props: ['group'],

    data() {
        return {
            isEditing: false,
            isSaving: false,
            name: null
        };
    },

    computed: {
        title() {
            return this.group ? 'Edit Group' : 'Create Group';
        }
    },

    created() {
        this.group || this.edit();
    },

    methods: {
        edit() {
            this.name = this.group ? this.group.name : '';
            this.isEditing = true;
        },

        async save() {
            try {
                if (!this.name) return;

                this.isSaving = true;

                let payload = {
                    uuid: this.group ? this.group.uuid : null,
                    name: this.name
                };

                // TODO: fold this into VF
                const url = '/api/orgs/:orgId/groups' + (this.group ? `/${this.group.uuid}` : '');
                const method = this.group ? 'put' : 'post';
                const response = await this.$http[method](url, payload);

                this.$emit('updated', { original: this.group, updated: response.data });

                this.isSaving = false;

                if (this.group) {
                    this.isEditing = false;
                }
            } catch (err) {
                this.$alert(this.title, err);
                this.$unmaskForm();
            }
        },

        cancelEditing() {
            this.name = null;
            this.isEditing = false;
            this.$emit('canceled');
        },

        async deleteGroup() {
            const result = await this.$confirm('Delete Group', `Are you sure you want to delete ${this.group.name}?`);
            if (!result) return;

            this.isSaving = true;

            try {
                await this.$http.delete('/api/orgs/:orgId/groups/' + this.group.uuid);
                this.isSaving = false;
                this.$emit('updated', { original: this.group });
            } catch (err) {
                this.$alert(this.title, err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
tr {
    td {
        @apply flex items-center relative;

        input {
            padding-right: 48px;
        }

        .controls {
            @apply absolute px-2;
            right: 8px;

            i {
                @apply p-1;
            }

            i:not(.fa-spinner-third) {
                @apply cursor-pointer;

                &:hover {
                    @apply text-gray-600;
                }
            }
        }

        .btn-destroy {
            @apply ml-auto;
        }

        span i,
        button {
            @apply invisible ml-2;
        }

        &:hover {
            span i,
            button {
                @apply visible cursor-pointer;
            }
        }

        input {
            @apply flex-1;
        }
    }
}
</style>
