<template>
    <modal id="wq-view" scrolls closeOnMaskClick closeX>
        <h1 slot="header">Wellness Questionnaire &mdash; Submission #{{ wqId }}</h1>

        <div v-if="!submission" class="flex-loading p-16">Loading...</div>
        <div v-else class="responses">
            <em>Submitted at <span v-datetime="submission.submittedAt" format="M/D/YY h:mm:ss a" /></em>
            <div v-for="(field, index) in submission.fields" :key="index" class="field" :class="['field-' + field.type]">
                <div class="prompt">{{ field.prompt }}</div>
                <template v-if="isInputOption(field.type)">
                    <div v-if="field.response === undefined" class="no-response">no response provided</div>
                    <div v-else-if="!Array.isArray(field.response)" class="response">{{ field.response }}</div>
                    <ul v-else class="multi-response">
                        <li v-for="(item, itemIndex) in field.response" :key="itemIndex">{{ item }}</li>
                    </ul>
                </template>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            wqId: null,
            submission: null
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            const result = await this.$http.get('/api/orgs/:orgId/reports/wellness-q/' + this.wqId);
            this.submission = result.data;
        },

        isInputOption(type) {
            return !['header', 'label'].includes(type);
        }
    }
};
</script>

<style lang="scss">
#wq-view {
    .vf-modal {
        min-width: 500px;
        max-width: 700px;
    }

    .responses {
        @apply p-4;
    }

    .field {
        @apply mt-4;
    }

    .field-header {
        @apply mt-6;

        &:first-child {
            @apply mt-0;
        }
    }

    .prompt {
        @apply font-bold;
    }

    .field-header .prompt {
        font-size: 14px;
    }

    .field-label .prompt {
        font-weight: normal;
    }

    .no-response {
        @apply italic;
    }

    ul.multi-response {
        @apply list-inside list-disc;
    }
}
</style>
