// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://542a24b75075444588eec99f8fd82a5e@sentry.s24.io/5',
        integrations: [new VueIntegration({ Vue, attachProps: true })]
    });
}

import '@fontsource/open-sans/latin.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import './common/styles/tailwind.css';

import App from './app';
import router from './router';

import './common/components/index';
import './common/filters/index';

// importing Vue foundation registers everything it provides
import VueFoundation from '@signal24/vue-foundation';

// configure some variable settings
VueFoundation.configure({
    async unauthorizedHttpResponseHandler() {
        // TODO: improve root instance accessibility
        if (Vue.prototype.$app.session) {
            Vue.prototype.$app.session = null;
            return true;
        }
    }
});

// replace :orgId in URLs
import axios from 'axios';
axios.interceptors.request.use(request => {
    request.url = request.url.replace('/:orgId/', () => '/' + Vue.prototype.$app.session?.activeOrg?.uuid + '/');
    return request;
});

// get the default store, which provides a session state, root injections, and a global error, if needed
const store = VueFoundation.getStore({
    isWsConnected: false
});

Vue.config.productionTip = process.env.NODE_ENV === 'production';

// TODO: add to VF
Vue.prototype.$delay = ms => new Promise(resolve => setTimeout(resolve, ms));
Vue.prototype.$throwUserError = msg => {
    let err = new Error(msg);
    err.code = 'USERERR';
    throw err;
};

const root = new Vue({
    router,
    render: h => h(App),
    data: { store }
}).$mount('#app');

// let VueFoundation know the root instance
VueFoundation.setRoot(root);
