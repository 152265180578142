<template>
    <div id="root-error">
        {{ message }}
    </div>
</template>

<script>
export default {
    props: ['message']
};
</script>

<style lang="scss">
#root-error {
    @apply flex-1 flex justify-center items-center p-24 text-xl text-gray-400;
}
</style>
