import Vue from 'vue';

Vue.filter('mac', input => {
    return (
        input.substr(0, 2) +
        ':' +
        input.substr(2, 2) +
        ':' +
        input.substr(4, 2) +
        ':' +
        input.substr(6, 2) +
        ':' +
        input.substr(8, 2) +
        ':' +
        input.substr(10, 2)
    );
});

Vue.filter('version', raw => {
    if (!raw) return raw;
    const matches = String(raw).match(/^([0-9]*?)([0-9]{1,3})([0-9]{3})$/);
    return parseInt(matches[1] || '0') + '.' + parseInt(matches[2]) + '.' + parseInt(matches[3]);
});
