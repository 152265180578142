import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import Dashboard from './pages/dashboard';
import Devices from './pages/devices';
import Logout from './pages/logout';
import People from './pages/people';
import Reports from './pages/reports';
import Settings from './pages/settings';
import SysAdmin from './pages/sysadmin';
import Setup from './pages/setup';

let routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/dashboard',
        component: Dashboard
    },
    {
        path: '/devices',
        component: Devices
    },
    {
        path: '/people',
        component: People
    },
    {
        path: '/reports',
        component: Reports
    },
    {
        path: '/settings',
        component: Settings
    },
    {
        path: '/sysadmin',
        component: SysAdmin
    },
    {
        path: '/setup',
        component: Setup
    },
    {
        path: '/logout',
        component: Logout
    }
];

registerRoutes(routes, routes);

let router = new Router({ routes });

router.afterEach(() => {
    window.$app && window.$app.checkSetup();
});

export default router;

function registerRoutes(rootRoutes, routes) {
    for (let route of routes) {
        if (route.component) {
            if (route.component.routes) {
                routes.push.apply(routes, route.component.routes);
                registerRoutes(rootRoutes, route.component.routes);
            }

            if (route.component.childRoutes) {
                route.children = route.component.childRoutes;
                registerRoutes(route.children, route.children);
            }
        }
    }
}
