<template>
    <page id="sysadmin">
        <div class="tabs">
            <router-link v-for="tab in tabs" :key="tab" :to="`/sysadmin/${tab}`">{{ getTitleForTab(tab).ucfirst() }}</router-link>
        </div>
        <router-view class="flex-1 ml-4" />
    </page>
</template>

<script>
import Orgs from './sysadmin/f-orgs';

const TAB_TITLES = {
    orgs: 'Organizations'
};

export default {
    store: ['session'],

    childRoutes: [
        {
            path: '',
            redirect: 'orgs'
        },
        {
            path: 'orgs',
            component: Orgs
        }
    ],

    computed: {
        tabs() {
            return ['orgs'];
        }
    },

    methods: {
        getTitleForTab(tab) {
            return TAB_TITLES[tab] || tab;
        }
    }
};
</script>

<style lang="scss">
#sysadmin {
    #root-content .inner-wrap {
        flex-direction: row !important;
    }

    .tabs {
        min-width: 200px;

        a {
            @apply block p-4 mb-2 rounded-md cursor-pointer;

            &:hover {
                @apply bg-gray-300;
            }

            &.router-link-active {
                @apply text-white;
                background: $button-primary-color;
                background: #2d3436;

                &:hover {
                    background: darken($color: $button-primary-color, $amount: 10%);
                    background: lighten($color: #2d3436, $amount: 10%);
                }
            }
        }
    }

    .container {
        @apply max-w-4xl mx-auto;
    }
}
</style>
