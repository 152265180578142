<template>
    <modal id="register-device">
        <h1 slot="header">Register Device</h1>

        <input v-model="registrationCode" ref="codeField" type="text" placeholder="registration code" v-autofocus />

        <div slot="footer">
            <button class="btn-primary" @click="register">Register</button>
            <button class="btn-default" @click="$dismiss()">Cancel</button>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            registrationCode: null
        };
    },

    methods: {
        async register() {
            if (!this.registrationCode) return;

            try {
                const code = this.registrationCode.replace(/ /g, '');

                if (!/^[0-9]{9}$/.test(code)) this.$throwUserError('The registration code provided is not valid. Please enter 9 digits.');

                this.$maskForm('.btn-primary');

                const response = await this.$http.post('/api/orgs/:orgId/devices', {
                    registrationCode: this.registrationCode
                });

                this.$dismiss(response.data);
            } catch (err) {
                await this.$alert('Register Device', err);
                this.$unmaskForm();
                this.$refs.codeField.focus();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vf-modal {
        min-width: 200px;
    }

    .vf-modal-content {
        @apply p-2;
    }
}

input {
    @apply w-full text-center;
}
</style>
