export default {
    allowed: 'Allowed',
    deny_stranger: 'Unidentified',
    deny_mask: 'Mask Required',
    deny_temp: 'High Temperature',
    deny_nfc: 'Unrecognized RFID Tag',
    deny_banned: 'Banned',
    deny_mc_invalid: 'Invalid Mobile Code',
    deny_mc_expired: 'Expired Mobile Code',
    deny_id_mismatch: 'ID Mismatch',
    deny_wq_required: 'Wellness Questionnaire Required'
};
