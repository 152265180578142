<template>
    <div />
</template>

<script>
export default {
    store: ['session'],

    async mounted() {
        this.$wait();

        await this.$http.post('/api/logout');

        this.$endWait();

        this.session = null;
        this.$router.replace('/');
    }
};
</script>
