<template>
    <modal id="edit-organization" @form-submit="save" scrolls>
        <h1 slot="header">{{ org.name }}: Configuration</h1>

        <table>
            <thead>
                <th>Context</th>
                <th>Key</th>
                <th>Value</th>
            </thead>
            <tbody>
                <tr v-if="!config" class="info">
                    <td colspan="3">Loading...</td>
                </tr>

                <tr v-else v-for="(param, index) in config" :key="index" :class="{ modified: param.value !== originalConfig[index].value }">
                    <td>{{ param.context }}</td>
                    <td>{{ param.key }}</td>
                    <td><input v-model="param.value" type="text" autocomplete="false" spellcheck="false" /></td>
                </tr>
            </tbody>
        </table>

        <div slot="footer">
            <button class="btn-primary" v-disabled="!config">Save</button>
            <button class="btn-default" @click.prevent="$dismiss()">Cancel</button>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            org: null,
            config: null,
            originalConfig: null
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            try {
                const response = await this.$http.get('/api/orgs/:orgId/sysadmin/orgs/' + this.org.uuid + '/config');
                this.config = response.data;
                this.originalConfig = this.config.map(item => Object.assign({}, item));
            } catch (err) {
                this.$alert('Configuration', err);
                this.$dismiss();
            }
        },

        async save() {
            let updates = {};

            for (let i = 0; i < this.config.length; i++) {
                if (this.config[i].value !== this.originalConfig[i].value) updates[this.config[i].key] = this.config[i].value;
            }

            if (Object.keys(updates).length == 0) return this.$dismiss();

            for (let key in updates) {
                try {
                    JSON.parse(updates[key]);
                } catch (err) {
                    return this.$alert('Save Configuration', key + ' is not a valid JSON value.');
                }
            }

            this.$maskForm('.btn-primary');

            try {
                await this.$http.post('/api/orgs/:orgId/sysadmin/orgs/' + this.org.uuid + '/config', updates);
                this.$dismiss();
            } catch (err) {
                this.$unmaskForm();
                this.$alert('Save Configuration', err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vf-modal {
        min-width: 900px;
    }

    .vf-modal-content {
        @apply p-2;
    }
}

tr.modified {
    @apply bg-yellow-300;
}

td:last-child {
    @apply w-full;
}

input {
    @apply w-full;
}
</style>
