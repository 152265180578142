<template>
    <div id="device-settings" class="container relative">
        <div class="setting">
            <span class="font-bold"
                >Require pre-filled wellness questionnaire
                <i
                    class="fa fa-question-circle"
                    v-tip="
                        `Enabling this feature will require individuals to fill out a wellness questionnaire. Once they complete the questionnaire, they will be provided a mobile code to scan on the device prior to scanning their face. Visit the Wellness Questionnaire tab of settings to build the form and/or retrieve the link.`
                    "
            /></span>

            <label
                class="toggle ml-auto"
                v-tip="!session.isProProduct && 'This is a Pro feature. Please contact your sales representative for more information.'"
            >
                <input type="checkbox" v-model="settings.shouldRequireWQ" v-disabled="!session.isProProduct" />
                <div />
            </label>
        </div>

        <div v-if="settings.shouldRequireWQ" class="setting">
            <span class="font-bold"
                >Wellness questionnaire acceptance window
                <i
                    class="fa fa-question-circle"
                    v-tip="`Wellness questionnaires filled out earlier than the configured number of minutes will not be accepted.`"
            /></span>

            <label class="ml-auto"><input type="number" v-model.number="settings.wqAcceptanceWindow" step="0.1" /> minutes</label>
        </div>

        <div class="setting">
            <span class="font-bold"
                >Primary identification method
                <i
                    class="fa fa-question-circle"
                    v-tip
                    tip='This option configures how the kiosk recognizes the people it scans. Note that when the "facial recognition" option is selected, a registered person with a configured NFC tag ID may use their NFC tag to improve facial recognition accuracy.'
            /></span>

            <div class="select-wrap ml-auto">
                <select v-model="settings.identificationMethod">
                    <option value="none">
                        None (only check temperature<template v-if="settings.shouldDenyEntryWithoutMask"> &amp; mask</template>)
                    </option>
                    <option value="facial">Facial recognition</option>

                    <template v-if="session.isProProduct">
                        <option value="nfc">NFC tag</option>
                        <option value="nfc_mc">NFC tag or mobile barcode</option>
                        <option value="mc">Mobile barcode</option>
                    </template>

                    <template v-else>
                        <option disabled>NFC tag (Pro only)</option>
                        <option disabled>NFC tag or mobile barcode (Pro only)</option>
                        <option disabled>Mobile barcode (Pro only)</option>
                    </template>
                </select>
            </div>
        </div>

        <div v-if="canUseFRAsSecondaryId" class="setting">
            <span class="font-bold"
                >Confirm identity with facial recognition
                <i
                    class="fa fa-question-circle"
                    v-tip
                    tip="If enabled, after a registered person scans their NFC tag or mobile barcode, facial recognition will be used to confirm that their face matches the registered photo to provide additional security."
            /></span>

            <label class="toggle ml-auto">
                <input type="checkbox" v-model="settings.shouldUseFRAsSecondaryId" />
                <div />
            </label>
        </div>

        <div class="setting stacked">
            <div class="row">
                <span class="font-bold">Deny entry to strangers</span>

                <div v-if="settings.identificationMethod === 'nfc'" class="ml-auto cannot-auth-strangers">
                    Strangers cannot authenticate when using NFC as the identification method.
                </div>
                <label v-else class="toggle ml-auto">
                    <input type="checkbox" v-model="settings.shouldDenyStrangers" />
                    <div />
                </label>
            </div>

            <div v-if="mustStrangersUseWQ && !settings.shouldDenyStrangers" class="row explanation">
                Since the configured identification method requires a mobile barcode, strangers must fill out the wellness questionnaire to receive a
                code to scan. See the <strong>Wellness Questionnaire</strong> tab to build a form for strangers to register.
            </div>
        </div>

        <div class="setting">
            <span class="font-bold">Deny access over temperature threshold</span>

            <div class="inputs-container">
                <input v-if="settings.shouldDenyOverTemp" v-model.number="settings.tempUpperLimit" type="number" step="0.1" maxlength="5" />

                <label class="toggle ml-auto">
                    <input type="checkbox" v-model="settings.shouldDenyOverTemp" />
                    <div />
                </label>
            </div>
        </div>

        <div class="setting">
            <span class="font-bold">Require mask for entry</span>

            <label class="toggle ml-auto">
                <input type="checkbox" v-model="settings.shouldDenyEntryWithoutMask" />
                <div />
            </label>
        </div>

        <div class="setting">
            <span class="font-bold">Play tone on denied access</span>

            <label class="toggle ml-auto">
                <input type="checkbox" v-model="computedAlertSound" />
                <div />
            </label>
        </div>

        <div class="setting">
            <span class="font-bold">Volume level</span>

            <label class="ml-auto">
                <div class="select-wrap">
                    <select v-model="settings.volume">
                        <option v-for="v in 15" :key="v" :value="v">{{ v }}</option>
                    </select>
                </div>
            </label>
        </div>

        <div class="setting">
            <span class="font-bold"
                >Temperature adjustment
                <i
                    class="fa fa-question-circle"
                    v-tip="
                        `In very hot or cold environments, it may be necessary to offset the temperature reading to compensate for the variability of skin surface temperature and/or excessive heating or cooling of the temperature sensor itself.`
                    "
            /></span>

            <label class="ml-auto"><input type="number" v-model.number="settings.tempAdjustmentOffset" step="0.1" /> &deg;F</label>
        </div>

        <div class="setting">
            <span class="font-bold"
                >Print identification badge
                <i
                    class="fa fa-question-circle"
                    v-tip="
                        `This feature requires an Epson TM-L90 printer. When this option is enabled, upon a successful scan, an identification badge will be printed.`
                    "
            /></span>

            <label
                class="toggle ml-auto"
                v-tip="!session.isProProduct && 'This is a Pro feature. Please contact your sales representative for more information.'"
            >
                <input type="checkbox" v-model="settings.shouldPrintBadge" v-disabled="!session.isProProduct" />
                <div />
            </label>
        </div>

        <template v-if="session.isProProduct">
            <div class="setting">
                <span class="font-bold">Relay mode</span>

                <div class="select-wrap ml-auto">
                    <select v-model="settings.relayMode">
                        <option :value="0">Disabled</option>
                        <option :value="1">Normally closed, open on approved entry</option>
                        <option :value="2">Normally open, close on approved entry</option>
                    </select>
                </div>
            </div>

            <div v-if="settings.relayMode > 0" class="setting">
                <span class="font-bold"
                    >Relay duration
                    <i
                        class="fa fa-question-circle"
                        v-tip="
                            `Specify the number of seconds the relay should remain ${
                                settings.relayMode == 1 ? 'open' : 'closed'
                            } after an approved entry scan.`
                        "
                /></span>

                <label class="ml-auto"><input type="number" v-model.number="settings.relayDelay" min="1" step="1" /></label>
            </div>
        </template>

        <div class="flex">
            <button :disabled="!haveSettingsChanged" class="btn-primary ml-auto" @click="save">Save Changes</button>
        </div>
    </div>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            haveSettingsChanged: false,
            settings: {
                identificationMethod: null,
                shouldUseFRAsSecondaryId: null,
                shouldRequireWQ: null,
                wqAcceptanceWindow: null,
                shouldDenyEntryWithoutMask: null,
                shouldDenyStrangers: null,
                shouldDenyOverTemp: null,
                tempUpperLimit: null,
                tempAdjustmentOffset: null,
                alertSound: null,
                volume: null,
                shouldPrintBadge: null,
                relayMode: null,
                relayDelay: null
            }
        };
    },

    computed: {
        canUseFRAsSecondaryId() {
            return ['nfc', 'nfc_mc', 'mc'].includes(this.settings.identificationMethod);
        },

        mustStrangersUseWQ() {
            return ['nfc_mc', 'mc'].includes(this.settings.identificationMethod) && !this.settings.shouldRequireWQ;
        },

        computedAlertSound: {
            get() {
                return this.settings.alertSound == 1;
            },

            set(v) {
                this.settings.alertSound = v ? 1 : 0;
            }
        }
    },

    watch: {
        session: {
            deep: true,
            handler() {
                this.load();
            }
        },

        settings: {
            deep: true,
            handler() {
                this.haveSettingsChanged = true;
            }
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.$wait();

            const response = await this.$http.get('/api/orgs/:orgId/config?keys=' + Object.keys(this.settings).join(','));
            this.settings = response.data;

            this.$endWait();

            this.$nextTick(() => {
                this.haveSettingsChanged = false;
            });
        },

        async save() {
            if (!this.settings.tempUpperLimit) {
                this.settings.tempUpperLimit = 100.5;
            }

            this.$wait();

            try {
                await this.$http.post('/api/orgs/:orgId/config', this.settings);
                this.haveSettingsChanged = false;
            } catch (err) {
                this.$alert('Save Changes', err);
            } finally {
                this.$endWait();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.fa-question-circle {
    @apply text-gray-500 cursor-pointer;
}

.cannot-auth-strangers {
    @apply text-right text-gray-400;
    max-width: 40%;
}

input[type='number'] {
    width: 50px;
}
</style>
