<template>
    <div class="v-switcher">
        <slot></slot>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
    name: 'VSwitcher',

    props: ['value', 'disabled'],

    watch: {
        value() {
            this.applyActiveClass();
        },

        disabled() {
            if (this.disabled) this.applyDisabled();
        }
    },

    mounted() {
        this.$buttons = $(this.$el).children('button');
        this.$buttons.each((index, button) => {
            let $button = $(button);
            let cValue = $button.attr('c-value');
            button.rawValue = cValue ? eval(cValue) : $button.attr('value');
        });

        this.$buttons.click(e => {
            e.preventDefault();
            this.handleButtonClicked(e.target);
        });

        this.applyActiveClass();
        this.disabled && this.applyDisabled();
    },

    methods: {
        handleButtonClicked(buttonEl) {
            this.$emit('input', buttonEl.rawValue);
        },

        applyActiveClass() {
            this.$buttons.removeClass('selected');
            this.$buttons.each((index, button) => {
                let buttonValue = button.rawValue;
                this.value === buttonValue && $(button).addClass('selected');
            });
        },

        applyDisabled() {
            this.$buttons[this.disabled ? 'attr' : 'removeAttr']('disabled', 'disabled');
        }
    }
};
</script>

<style lang="scss">
.v-switcher {
    display: flex;

    button:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    button:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
