<template>
    <div id="app" :class="{ 'ws-connected': isWsConnected }">
        <error-display v-if="globalError" :message="globalError" />
        <router-view v-else-if="session" />
        <login v-else />
        <component v-for="injection in rootInjections" :key="injection.id" :is="injection" />
    </div>
</template>

<script>
import Vue from 'vue';
import ErrorDisplay from '@/pages/error';
import Login from '@/pages/login';
import WebSocketClient from '@/ws';

export default {
    name: 'app',

    components: {
        ErrorDisplay,
        Login
    },

    store: ['session', 'globalError', 'rootInjections', 'isWsConnected'],

    watch: {
        session() {
            this.$ws.setOrgId(this.session?.activeOrg?.uuid);
            this.checkSetup();
        }
    },

    created() {
        Vue.prototype.$app = this;
        window.$app = this;

        const ws = new WebSocketClient();
        ws.addEventListener('connected', () => (this.isWsConnected = true));
        ws.addEventListener('disconnected', () => (this.isWsConnected = false));
        ws.addEventListener('cloudconnector.connection', e => {
            this.session.isCloudConnectorConnected = e.data.isConnected;
        });
        Vue.prototype.$ws = ws;
    },

    methods: {
        checkSetup() {
            if (this.$route.path === '/setup') return;
            if (!this.session) return;
            if (this.session.isServerConfigured !== false) return;
            this.$router.replace('/setup');
        },

        setSession(data) {
            data.isProProduct = data.activeOrg.product === 'pro';
            this.session = data;
        }
    }
};
</script>

<style lang="scss">
@import './common/styles/common.scss';
</style>
