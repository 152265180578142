<template>
    <page id="devices">
        <div class="filters flex">
            <button class="btn-primary" v-disabled="!devices" @click="registerDevice"><i class="fa fa-plus" /> Register Device</button>
            <button class="ml-auto btn-primary" v-disabled="!devices" @click="load"><i class="fas fa-sync" /></button>
        </div>

        <table>
            <thead>
                <tr>
                    <th class="connection-status"></th>
                    <th class="text-left">Name</th>
                    <th>Software</th>
                    <th>MAC Address</th>
                    <th>IP Address</th>
                    <th class="text-left">Connection</th>
                    <th class="text-left">Last Scan</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!devices" class="info">
                    <td colspan="7">Loading...</td>
                </tr>
                <tr v-else-if="devices.length == 0" class="info">
                    <td colspan="7">No devices have been registered to this organization.</td>
                </tr>
                <tr
                    v-else
                    v-for="device in devices"
                    :key="device.mac"
                    class="clickable"
                    :class="{ 'is-connected': device.isConnected }"
                    @click="viewDevice(device)"
                    @contextmenu.prevent="showContextMenu($event, device)"
                >
                    <td class="connection-status"><i /></td>
                    <td>
                        {{ device.name }}
                        <template v-if="!device.isLicensed">(Unlicensed)</template>
                    </td>
                    <td>
                        <template v-if="device.softwareUpdateStatus">{{ device.softwareUpdateStatus }}</template>
                        <template v-else>
                            {{ device.softwareVersion | version }}
                            <button
                                v-if="device.softwareVersion < session.swVersionDeviceLatest && device.isConnected"
                                class="btn-primary update-btn"
                                @click.stop="updateSoftware(device)"
                            >
                                update
                            </button>
                        </template>
                    </td>
                    <td>{{ device.mac | mac }}</td>
                    <td>{{ device.lanIp | dash }}</td>
                    <td>
                        <template v-if="!isWsConnected">...</template>
                        <template v-else-if="device.isConnected">Connected <span v-duration="device.connectedAt" /> ago</template>
                        <template v-else>Disconnected <span v-duration="device.lastDisconnectedAt" /> ago</template>
                    </td>
                    <td>
                        <span v-if="device.lastScanAt" v-duration="device.lastScanAt" />
                        <template v-else>Never</template>
                    </td>
                </tr>
            </tbody>
        </table>
    </page>
</template>

<script>
import DeviceUpdateStatusHelper from '@/common/helpers/device-update-status';
import RegisterDeviceModal from './devices/m-register';
import ViewDeviceModal from './devices/m-view';

export default {
    store: ['session', 'isWsConnected'],

    data() {
        return {
            devices: null
        };
    },

    computed: {
        orgId() {
            return this.session.activeOrg.uuid;
        }
    },

    watch: {
        session() {
            this.load();
        }
    },

    created() {
        this.$ws.addEventListener('device.connection', this.handleDeviceConnectionUpdate);
        this.$ws.addEventListener('device.swupdate', this.handleDeviceSWUpdateStatus);
        this.$ws.addEventListener('scans', this.handleDeviceScan);
    },

    mounted() {
        this.load();
    },

    beforeDestroy() {
        this.$ws.removeEventListener('device.connection', this.handleDeviceConnectionUpdate);
        this.$ws.removeEventListener('device.swupdate', this.handleDeviceSWUpdateStatus);
        this.$ws.removeEventListener('scans', this.handleDeviceScan);
    },

    methods: {
        async load() {
            this.devices = null;
            const response = await this.$http.get('/api/orgs/:orgId/devices');
            this.devices = response.data;
            this.session.activeOrg.devices = this.devices.pluck('name', 'mac');
        },

        async reloadDevice(device) {
            const response = await this.$http.get('/api/orgs/:orgId/devices/' + device.mac);
            Object.assign(device, response.data);
        },

        async registerDevice() {
            const newDevice = await this.$modal(RegisterDeviceModal);
            if (!newDevice) return;

            this.devices.push(newDevice);
            this.devices.sortBy('name');

            this.$set(this.session.activeOrg.devices, newDevice.mac, newDevice.name);
        },

        viewDevice(device) {
            this.$modal(ViewDeviceModal, { device: device }, component => {
                component.$on('name-updated', updatedName => {
                    device.name = updatedName;
                    this.devices.sortBy('name');

                    this.$set(this.session.activeOrg.devices, device.mac, updatedName);
                });
            });
        },

        showContextMenu(e, device) {
            let items = [
                {
                    title: 'Reboot',
                    handler: () => this.rebootDevice(device)
                },
                {
                    title: 'Unregister',
                    handler: () => this.unregisterDevice(device)
                }
            ];

            this.session.isSuperUser &&
                items.unshift({
                    title: 'Get Device Logcat',
                    handler: e => this.getDeviceLogcat(e, device)
                });

            this.$contextMenu(e, { items });
        },

        getDeviceLogcat(e, device) {
            let url = '/api/orgs/' + this.session.activeOrg.uuid + '/devices/' + device.mac + '/logcat';
            if (e.altKey) url += '?mins=_';
            window.open(url);
        },

        async rebootDevice(device) {
            let response = await this.$confirm('Reboot Device', `Are you sure you want to reboot this device?`);
            if (!response) return;

            try {
                await this.$http.post('/api/orgs/:orgId/devices/' + device.mac + '/reboot');
            } catch (err) {
                this.$alert('Reboot Device', err);
            }
        },

        async unregisterDevice(device) {
            let response = await this.$confirm('Unregister Device', `Are you sure you want to unregister this device?`);
            if (!response) return;

            try {
                await this.$http.delete('/api/orgs/:orgId/devices/' + device.mac);
                this.devices.remove(device);
            } catch (err) {
                this.$alert('Unregister Device', err);
            }
        },

        async updateSoftware(device) {
            this.$set(device, 'softwareUpdateStatus', 'Requesting...');

            try {
                await this.$http.post('/api/orgs/:orgId/devices/' + device.mac + '/install-update');
            } catch (err) {
                device.softwareUpdateStatus = null;
                this.$alert('Update Software', err);
            }
        },

        handleDeviceConnectionUpdate(e) {
            if (!this.devices) return;
            const device = this.devices.find(device => device.mac === e.data.mac);
            if (!device) return;
            if (device.softwareUpdateStatus) device.softwareUpdateStatus = null;
            device.isConnected = e.data.isConnected;
            device[device.isConnected ? 'lastConnectedAt' : 'lastDisconnectedAt'] = new Date().toISOString();
            device.isConnected && this.reloadDevice(device);
        },

        handleDeviceSWUpdateStatus(e) {
            if (!this.devices) return;
            const device = this.devices.find(device => device.mac === e.data.mac);
            if (!device) return;
            this.$set(device, 'softwareUpdateStatus', DeviceUpdateStatusHelper.translateStatus(e.data.status));
        },

        async handleDeviceScan(e) {
            let device = this.devices.find(device => device.mac == e.data.deviceId);

            if (!device) return;
            device.lastScanAt = e.latestTimestamp;
        }
    }
};
</script>

<style lang="scss">
#devices {
    .connection-status {
        width: 28px;

        i {
            @apply block border border-gray-500 rounded-full;
            width: 12px;
            height: 12px;
        }
    }

    tr.is-connected .connection-status i {
        border-color: $connected-color;
        background: $connected-color;
    }

    .update-btn {
        @apply ml-1;
        height: 24px;
    }
}

#app:not(.ws-connected) {
    #devices .connection-status i {
        visibility: hidden;
    }
}
</style>
