<template>
    <modal id="edit-groups" scrolls>
        <h1 slot="header">Edit Groups</h1>

        <table>
            <tbody>
                <group-row v-for="group in groups" :key="group.uuid" :group="group" @updated="patchGroup" />
                <group-row v-if="isCreatingGroup" @updated="patchGroup" @canceled="cancelCreate" />
            </tbody>
        </table>

        <div v-if="!isCreatingGroup" class="add-btn-wrap">
            <button class="btn-default" @click="addGroup"><i class="fa fa-plus" /> Add Group</button>
        </div>

        <div slot="footer">
            <button class="btn-default" @click="$dismiss()">Close</button>
        </div>
    </modal>
</template>

<script>
import GroupRow from './m-groups/c-group-row';

export default {
    store: ['session'],

    components: {
        GroupRow
    },

    data() {
        return {
            groups: null,
            isCreatingGroup: false
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            const response = await this.$http.get('/api/orgs/:orgId/groups');
            this.groups = response.data;
            this.session.activeOrg.groups = this.groups.pluck('name', 'uuid');
        },

        addGroup() {
            this.isCreatingGroup = true;
        },

        cancelCreate() {
            this.isCreatingGroup = false;
        },

        patchGroup(data) {
            if (!data.original) {
                this.groups.push(data.updated);
                this.groups.sort((a, b) => a.name.localeCompare(b.name));
                this.isCreatingGroup = false;
                this.$set(this.session.activeOrg.groups, data.updated.uuid, data.updated.name);
            } else if (!data.updated) {
                this.groups.remove(data.original);
                this.$delete(this.session.activeOrg.groups, data.original.uuid);
            } else {
                Object.assign(data.original, data.updated);
                this.groups.sort((a, b) => a.name.localeCompare(b.name));
                this.$set(this.session.activeOrg.groups, data.updated.uuid, data.updated.name);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vf-modal {
        min-width: 300px;
    }

    .vf-modal-content {
        @apply p-2;
    }
}

.add-btn-wrap {
    @apply flex items-center;
    padding: 8px 0;

    button {
        @apply ml-2;
    }
}
</style>
