<template>
    <modal id="view-device" closeOnMaskClick closeX>
        <h1 slot="header">View Device</h1>

        <div class="info">
            <div class="flex-1 flex flex-col">
                <div class="flex items-center">
                    <i class="status" :class="{ 'is-connected': device.isConnected }" />
                    <device-name :device="device" @name-updated="handleNameUpdated" />
                </div>

                <div v-if="device.isConnected">Connected: <span v-duration="device.connectedAt" /> ago</div>
                <div v-else>Disconnected: <span v-duration="device.lastDisconnectedAt" /> ago</div>

                <div v-if="device.lastScanAt">Last Scan: <span v-duration="device.lastScanAt" /></div>
                <span v-else>Never</span>
            </div>

            <div class="flex text-right">
                <button
                    v-if="device.softwareVersion < session.swVersionDeviceLatest && device.isConnected"
                    class="btn-primary"
                    @click.prevent="updateSoftware"
                >
                    Update
                </button>
                <button class="btn-default ml-2" @click.prevent="openDeviceSettings"><i class="fas fa-cog" /></button>
            </div>
        </div>

        <scans class="in-modal" :scans="scans" :device="device" :loading="isLoadingScans" @load-more="loadMoreScans" />
    </modal>
</template>

<script>
import DeviceUpdateStatusHelper from '@/common/helpers/device-update-status';
import DeviceName from './m-view/f-device-name';
import Scans from '@/common/fragments/scans';
import DeviceSettings from './m-view/m-device-settings';

export default {
    store: ['session'],

    components: {
        DeviceName,
        Scans
    },

    data() {
        return {
            view: 'grid',
            scans: null,
            isLoadingScans: false,
            canLoadMoreScans: true
        };
    },

    created() {
        this.$ws.addEventListener('device.connection', this.handleDeviceConnectionUpdate);
        this.$ws.addEventListener('device.swupdate', this.handleDeviceSWUpdateStatus);
        this.$ws.addEventListener('scans', this.handleDeviceScan);
    },

    mounted() {
        this.reloadDevice();
        this.loadScans();
    },

    beforeDestroy() {
        this.$ws.removeEventListener('device.connection', this.handleDeviceConnectionUpdate);
        this.$ws.removeEventListener('device.swupdate', this.handleDeviceSWUpdateStatus);
        this.$ws.removeEventListener('scans', this.handleDeviceScan);
    },

    methods: {
        async loadScans() {
            const response = await this.$http.get('/api/orgs/:orgId/scans?deviceId=' + this.device.mac);
            this.scans = response.data;
        },

        async loadMoreScans() {
            if (!this.canLoadMoreScans) return;
            if (!this.scans.length) return;
            this.isLoadingScans = true;
            const response = await this.$http.get('/api/orgs/:orgId/scans', {
                params: {
                    deviceId: this.device.mac,
                    beforeId: this.scans.last().id
                }
            });
            this.scans.push.apply(this.scans, response.data);
            this.canLoadMoreScans = response.data.length > 0;
            this.isLoadingScans = false;
        },

        async reloadDevice() {
            const response = await this.$http.get('/api/orgs/:orgId/devices/' + this.device.mac);
            Object.assign(this.device, response.data);
        },

        async updateSoftware() {
            this.$set(this.device, 'softwareUpdateStatus', 'Requesting...');

            try {
                await this.$http.post('/api/orgs/:orgId/devices/' + this.device.mac + '/install-update');
            } catch (err) {
                this.device.softwareUpdateStatus = null;
                this.$alert('Update Software', err);
            }
        },

        async openDeviceSettings() {
            await this.$modal(DeviceSettings, { device: this.device });
        },

        handleDeviceConnectionUpdate(e) {
            if (e.data.mac !== this.device.mac) return;
            this.device.isConnected = e.data.isConnected;
            if (this.device.softwareUpdateStatus) this.device.softwareUpdateStatus = null;
            this.device[this.device.isConnected ? 'lastConnectedAt' : 'lastDisconnectedAt'] = new Date().toISOString();
            this.device.isConnected && this.reloadDevice();
        },

        handleDeviceSWUpdateStatus(e) {
            if (e.data.mac !== this.device.mac) return;
            this.$set(this.device, 'softwareUpdateStatus', DeviceUpdateStatusHelper.translateStatus(e.data.status));
        },

        async handleDeviceScan(e) {
            let scanIds = e.data.scanIds;
            for (let i = 0; i < scanIds.length; i++) {
                let response = await this.$http.get('/api/orgs/:orgId/scans/' + scanIds[i]);
                let scan = response.data;
                this.scans.unshift(scan);
            }
        },

        handleNameUpdated(updatedName) {
            this.device.name = updatedName;
            this.$emit('name-updated', updatedName);
        }
    }
};
</script>

<style lang="scss">
#view-device {
    .vf-modal {
        @apply min-h-0;
        min-width: 700px;
        max-width: 1000px;
        width: 100%;
    }

    .vf-modal-content {
        @apply flex flex-col min-h-0;
    }

    .info {
        @apply flex p-4 flex-shrink-0;

        i.status {
            @apply block border border-gray-500 rounded-full mr-2;
            width: 12px;
            height: 12px;

            &.is-connected {
                border-color: $connected-color;
                background: $connected-color;
            }
        }

        b {
            @apply mr-1;
        }

        .meta {
            span {
                @apply flex items-center justify-end;
            }

            button {
                @apply ml-2;
            }
        }
    }

    .update-btn {
        height: 24px;
    }
}
</style>
