<template>
    <modal id="edit-person" scrolls>
        <h1 slot="header">{{ title }}</h1>

        <div class="photo-wrap" :class="{ 'has-photo': person.isFaceRegistered }">
            <div class="photo">
                <template v-if="person.isFaceRegistered">
                    <img ref="photoImg" :src="photoSrc" />
                    <div class="buttons">
                        <button @click.prevent="selectPhoto">Replace</button>
                        <button @click.prevent="removePhoto">Remove</button>
                    </div>
                </template>
                <div v-else class="placeholder" @click="selectPhoto">
                    <i class="fas fa-user" />
                    <span>Click to upload a photo</span>
                </div>

                <div
                    class="request"
                    :class="{ enabled: shouldRequestPhoto, disabled: !session.isProProduct }"
                    v-tip="!session.isProProduct && 'This is a Pro feature. Please contact your sales representative for more information.'"
                >
                    <label>
                        <input type="checkbox" v-model="shouldRequestPhoto" v-disabled="!session.isProProduct" />
                        Request via email/SMS
                    </label>
                </div>
            </div>
        </div>

        <div class="inputs">
            <div class="row">
                <div class="input">
                    <label>ID</label>
                    <input v-model="person.customIdentifier" type="text" v-autofocus />
                </div>

                <div class="input">
                    <label>Group</label>
                    <smart-select
                        v-model="group"
                        url="/api/orgs/:orgId/groups"
                        :disabled="!session.isProProduct"
                        v-tip="!session.isProProduct && 'This is a Pro feature. Please contact your sales representative for more information.'"
                    />
                    <!-- todo: add a 'manage' footer -->
                    <!-- todo: fix tab key when disabled -->
                </div>
            </div>

            <div class="row">
                <div class="input required">
                    <label>First Name</label>
                    <input v-model="person.firstName" type="text" required />
                </div>

                <div class="input required">
                    <label>Last Name</label>
                    <input v-model="person.lastName" type="text" required />
                </div>
            </div>

            <div class="row">
                <div class="input">
                    <label>E-mail Address</label>
                    <input v-model="person.email" type="email" />
                </div>

                <div class="input">
                    <label>Phone</label>
                    <input v-model="person.phone" type="tel" @blur="formatPhone" />
                </div>
            </div>

            <div class="row">
                <div
                    class="input"
                    v-tip="!session.isProProduct && 'This is a Pro feature. Please contact your sales representative for more information.'"
                >
                    <label>NFC Tag ID</label>
                    <input v-model="nfcId" type="text" v-disabled="!session.isProProduct" @paste="handleNfcIdPasted" />
                </div>

                <div
                    class="input status-select"
                    v-tip="!session.isProProduct && 'This is a Pro feature. Please contact your sales representative for more information.'"
                >
                    <label>Status</label>
                    <v-switcher v-model="person.isBanned">
                        <button c-value="false" v-disabled="!session.isProProduct">Allowed</button>
                        <button c-value="true" class="banned" v-disabled="!session.isProProduct">Banned</button>
                    </v-switcher>
                </div>
            </div>
        </div>

        <div slot="footer">
            <button class="btn-primary" @click="save">Save</button>
            <button class="btn-default" @click.prevent="$dismiss()">Cancel</button>
        </div>
    </modal>
</template>

<script>
import { convertFromWiegand, convertToWiegand } from '@/common/helpers/wiegand';

export default {
    store: ['session'],

    data() {
        return {
            person: {
                customIdentifier: null,
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                nfcId: null,
                isFaceRegistered: false,
                isBanned: false
            },
            group: {
                uuid: null,
                name: null
            },
            nfcId: null,
            photoSrc: null,
            photoFile: null,
            shouldRequestPhoto: null
        };
    },

    computed: {
        title() {
            return (this.person.uuid ? 'Edit' : 'Add') + ' Person';
        }
    },

    created() {
        if (this.person.nfcId) {
            this.nfcId = convertFromWiegand(this.person.nfcId);
        }

        if (this.person.isFaceRegistered) {
            this.photoSrc = `/api/orgs/${this.session.activeOrg.uuid}/people/${this.person.uuid}/photo?v=${this.person.photoVersion}`;
        }
    },

    methods: {
        selectPhoto() {
            let input = document.createElement('input');
            input.type = 'file';
            input.accept = '.jpg, .jpeg';
            input.addEventListener('change', this.handlePhotoSelected);
            input.click();
        },

        handlePhotoSelected(e) {
            const file = e.target.files[0];
            if (!file) return;

            if (file.size > 15 * 1024 * 1024) return this.$alert(this.title, 'Photo uploads are limited to 5 MB.');

            const reader = new FileReader();
            reader.addEventListener('load', e => {
                this.person.isFaceRegistered = true;
                this.photoFile = file;
                this.photoSrc = e.target.result;
            });
            reader.readAsDataURL(file);
        },

        removePhoto() {
            this.person.isFaceRegistered = false;
            this.photoFile = null;
            this.photoSrc = null;
        },

        formatPhone() {
            this.person.phone = formatPhone(this.person.phone);
        },

        handleNfcIdPasted() {
            setTimeout(() => {
                if (!/^[0-9]+$/.test(this.nfcId)) return;
                this.nfcId = convertFromWiegand(this.nfcId);
            }, 10);
        },

        async save() {
            try {
                if (!this.person.firstName || !this.person.lastName) this.$throwUserError('Please enter a first and last name.');
                if (this.nfcId?.length) {
                    if (this.nfcId.length < 11 || !/^([0-9a-f]{2}:)+[0-9a-f]{2}$/i.test(this.nfcId))
                        this.$throwUserError('The NFC tag ID provided is not valid.');
                }

                this.person.nfcId = this.nfcId ? convertToWiegand(this.nfcId) : null;

                this.$maskForm('.btn-primary');

                let payload = {
                    ...this.person,
                    groupId: this.group.uuid,
                    shouldRequestPhoto: this.shouldRequestPhoto
                };

                if (this.photoFile) {
                    let formData = new FormData();
                    formData.append('_payload', JSON.stringify(payload));
                    formData.append('photo', this.photoFile);
                    payload = formData;
                }

                // TODO: fold this into VF
                const url = '/api/orgs/:orgId/people' + (this.person.uuid ? `/${this.person.uuid}` : '');
                const method = this.person.uuid ? 'put' : 'post';
                const response = await this.$http[method](url, payload);
                this.$dismiss(response.data);
            } catch (err) {
                this.$alert(this.title, err);
                this.$unmaskForm();
            }
        }
    }
};

function formatPhone(phone) {
    if (!phone) return phone;
    let strippedPhone = phone.replace(/[^0-9]/g, '');
    if (strippedPhone.length == 11 && strippedPhone.substr(0, 1) == '1') strippedPhone = strippedPhone.substr(1);
    if (strippedPhone.length != 10) return phone;
    return `(${strippedPhone.substr(0, 3)}) ${strippedPhone.substr(3, 3)}-${strippedPhone.substr(6)}`;
}
</script>

<style lang="scss" scoped>
::v-deep {
    .vf-modal {
        min-width: 200px;
    }

    .vf-modal-content {
        @apply p-2;
    }
}

.photo-wrap {
    @apply flex justify-center my-2;

    .photo {
        @apply h-64 w-64 flex relative;
    }

    img {
        @apply flex-1 object-cover rounded-lg;
    }

    .placeholder {
        @apply flex-1 flex flex-col items-center justify-center bg-gray-300 border border-gray-400 text-gray-600 rounded-lg cursor-pointer;

        i {
            @apply text-4xl mb-4;
        }

        &:hover {
            @apply bg-gray-400;
        }
    }

    .buttons {
        @apply absolute w-full h-full flex items-center justify-center invisible;

        button {
            @apply text-gray-800 mx-1;
            background: rgba(255, 255, 255, 0.75);

            &:focus,
            &:hover {
                background: white;
            }
        }
    }

    .request {
        @apply absolute bottom-0 mb-3 w-full flex justify-center;

        label {
            @apply p-2 rounded-md cursor-pointer flex items-center;
            background: rgba(255, 255, 255, 0.75);

            input {
                @apply mr-1;
            }
        }

        &.disabled {
            color: #aaa;
        }
    }

    &.has-photo {
        .request:not(.enabled) {
            @apply invisible;
        }

        .photo:hover {
            .buttons,
            .request {
                @apply visible;
            }
        }
    }
}

.row {
    @apply flex;

    .input {
        @apply flex-1 flex flex-col m-2;

        label {
            @apply mb-1;
        }

        input {
            @apply w-full;
        }

        &.required label {
            font-weight: bold;
        }
    }
}

.status-select {
    .banned:not([disabled]) {
        &.selected {
            background: $button-destructive-color;
        }

        &:hover,
        &:focus {
            color: white;
            background: darken($button-destructive-color, 10%) !important;
        }
    }
}
</style>
