<template>
    <div class="scan-tile" :class="{ denied: scan.result !== 'allowed' }">
        <div class="photo-wrap">
            <img v-if="scan.hasPhoto" :src="`/api/orgs/${session.activeOrg.uuid}/scans/${scan.id}/photo`" />
            <div v-else class="placeholder"><i class="fas fa-question-circle" /></div>
            <div v-if="scan.result !== 'allowed'" class="denied-reason">{{ scan.result | formatResult }}</div>
        </div>
        <div class="flex mt-1">
            <div class="flex-1 min-w-0">
                <div class="font-bold">
                    <div v-if="!person" class="person truncate" :class="{ unknown: !scan.person }">
                        {{ scan.person ? `${scan.person.lastName}, ${scan.person.firstName}` : 'Unidentified' }}
                    </div>
                    <div v-else-if="!device" class="truncate">{{ deviceNamesById[scan.deviceId] || 'unknown device ' + scan.deviceId }}</div>
                </div>

                <div v-if="!device && !person" class="truncate">{{ deviceNamesById[scan.deviceId] || 'unknown device ' + scan.deviceId }}</div>
                <div v-datetime="scan.scannedAt" format="M/D/YY h:mm:ss a" />
            </div>

            <div class="flex flex-col flex-shrink-0">
                <div v-if="scan.temperature != 0" class="pl-1 font-bold">{{ scan.temperature }}°</div>
                <div v-if="scan.wqId" class="mt-auto ml-auto">
                    <i class="fas fa-comment-edit text-xl cursor-pointer hover:text-gray-500" @click="showWQ" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RESULT_DESCRIPTIONS from '@/common/maps/scan-result';
import WQViewModal from '@/common/modals/wq-view';

export default {
    store: ['session'],
    props: ['scan', 'device', 'person'],

    data() {
        return {};
    },

    filters: {
        formatResult(value) {
            return RESULT_DESCRIPTIONS[value];
        }
    },

    computed: {
        deviceNamesById() {
            return this.session.activeOrg.devices;
        }
    },

    methods: {
        showWQ() {
            this.$modal(WQViewModal, {
                wqId: this.scan.wqId
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.scan-tile {
    @apply flex-1 flex flex-col rounded-md border-2 bg-green-100 border-green-500 p-2;
    min-width: 164px;

    &:hover {
        @apply bg-green-200;
    }

    .photo-wrap {
        @apply relative rounded-md overflow-hidden w-full;
        padding-bottom: 100%;

        img {
            @apply absolute object-cover object-center w-full h-full;
        }

        .placeholder {
            @apply absolute flex items-center justify-center bg-gray-300 w-full h-full;

            i {
                @apply text-3xl;
            }
        }

        .denied-reason {
            @apply absolute w-full bg-red-700 text-white text-center p-2;
            --bg-opacity: 0.7;
            bottom: 1em;
        }
    }

    .person.unknown {
        @apply italic;
    }

    &.denied {
        @apply bg-red-100 border-red-500;

        &:hover {
            @apply bg-red-200;
        }
    }
}
</style>
