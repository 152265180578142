<template>
    <modal id="wqfb-edit-field" scrolls>
        <h1 slot="header">Add Field</h1>

        <div v-if="!field.type" class="type-selection">
            <button v-for="input in inputOptions" :key="input.type" class="btn-default input-type" @click="field.type = input.type">
                <i :class="`${input.iconStyle} fa-${input.icon}`" />
                <span class="type">{{ input.description || input.type.ucfirst() }}</span>
            </button>
        </div>

        <div v-else class="info">
            <div class="editor">
                <div class="field">
                    <label>Display To</label>
                    <v-switcher v-model="field.audience">
                        <button value="both">Both</button>
                        <button value="registered">Registered People</button>
                        <button value="strangers">Strangers</button>
                    </v-switcher>
                </div>

                <div class="field">
                    <label>Field Type</label>
                    <div class="select-wrap">
                        <select v-model="field.type">
                            <option v-for="input in inputOptions" :key="input.type" :value="input.type">
                                {{ input.description || input.type.ucfirst() }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="field">
                    <label>Prompt</label>
                    <textarea v-model="field.prompt" class="w-full h-16" v-autofocus />
                </div>

                <div v-if="isPlaceholderAvailable(field.type)" class="field">
                    <label>Placeholder</label>
                    <input v-model="field.placeholder" type="text" class="w-full" />
                </div>

                <div v-if="isMultiOption(field.type)" class="field">
                    <label>Options</label>
                    <input
                        v-for="(option, index) in field.options"
                        :key="index"
                        v-model="field.options[index]"
                        type="text"
                        class="mb-2"
                        @input="checkLastField(index)"
                    />
                </div>

                <div v-if="isInputOption(field.type)" class="field">
                    <label>Required</label>
                    <v-switcher v-model="field.required">
                        <button c-value="true">Yes</button>
                        <button c-value="false">No</button>
                    </v-switcher>
                </div>
            </div>

            <div class="preview wqfb-fields">
                <h2>Preview</h2>
                <div v-if="isMultiOption(field.type) && previewOptions.length == 0" class="field">Enter at least one option to see preview.</div>
                <div v-else class="field" :class="['field-' + field.type]">
                    <label class="prompt">{{ field.prompt || 'Prompt' }}</label>

                    <select v-if="field.type == 'select'" class="single-input">
                        <option v-for="(option, index) in previewOptions" :key="index">{{ option }}</option>
                    </select>

                    <div v-else-if="isMultiOption(field.type)" class="multi-input-container">
                        <label v-for="(option, index) in previewOptions" :key="index" class="clickable-label">
                            <input :type="field.type" :name="field.prompt" :value="index" />
                            {{ option }}
                        </label>
                    </div>

                    <input v-else-if="isInputOption(field.type)" class="single-input" :type="field.type" :placeholder="field.placeholder" />
                </div>
            </div>
        </div>
        <div slot="footer">
            <button class="btn-primary" :disabled="shouldDisableSave" @click="save">Save</button>
            <button class="btn-default" @click="$dismiss()">Cancel</button>
        </div>
    </modal>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            inputOptions: [
                {
                    type: 'header',
                    icon: 'heading',
                    iconStyle: 'fas'
                },
                {
                    type: 'label',
                    icon: 'font-case',
                    iconStyle: 'fad'
                },
                {
                    type: 'text',
                    icon: 'keyboard',
                    iconStyle: 'fal'
                },
                {
                    type: 'email',
                    icon: 'envelope',
                    iconStyle: 'fas'
                },
                {
                    description: 'Phone',
                    type: 'tel',
                    icon: 'phone-alt',
                    iconStyle: 'fas'
                },
                {
                    type: 'number',
                    icon: 'hashtag',
                    iconStyle: 'fad'
                },
                {
                    type: 'checkbox',
                    icon: 'check-square',
                    iconStyle: 'fad'
                },
                {
                    type: 'radio',
                    icon: 'circle',
                    iconStyle: 'fad'
                },
                {
                    description: 'Dropdown',
                    type: 'select',
                    icon: 'mouse-pointer',
                    iconStyle: 'fas'
                },
                {
                    type: 'date',
                    icon: 'calendar-alt',
                    iconStyle: 'fad'
                }
            ],
            field: {
                audience: 'both',
                type: null,
                prompt: null,
                placeholder: null,
                options: [''],
                value: null,
                required: false
            }
        };
    },

    computed: {
        previewOptions() {
            return this.field.options.filter(x => x.length);
        },

        shouldDisableSave() {
            if (!this.field.prompt) return true;
            if (this.isMultiOption(this.field.type) && !this.previewOptions.length) return true;
            return false;
        }
    },

    methods: {
        isPlaceholderAvailable(type) {
            return ['text', 'number', 'tel', 'email'].includes(type);
        },

        isMultiOption(type) {
            return ['radio', 'checkbox', 'select'].includes(type);
        },

        isInputOption(type) {
            return !['header', 'label'].includes(type);
        },

        checkLastField() {
            let length = this.field.options.length;
            if (this.field.options[length - 1].length) {
                this.field.options.push('');
            } else if (length > 2 && !this.field.options[length - 2].length) {
                this.field.options.pop();
            }
        },

        trimOptions() {
            this.field.options.forEach((option, index) => {
                if (!option.length) this.field.options.splice(index, 1);
            });
        },

        save() {
            this.isMultiOption(this.field.type) && this.trimOptions();
            this.$dismiss(this.field);
        }
    }
};
</script>

<style lang="scss">
#wqfb-edit-field {
    .vf-modal {
        @apply min-h-0;
        min-width: 300px;
        max-width: 700px;
        width: 100%;
    }

    .vf-modal-content {
        @apply flex flex-col min-h-0;
    }

    .type-selection {
        @apply flex items-center justify-center flex-wrap pt-4 m-auto;
        max-width: 400px;

        .input-type {
            @apply flex flex-col items-center justify-center p-2 h-24 w-24 mr-8 mb-4;

            &:nth-of-type(4),
            &:nth-of-type(8) {
                @apply mr-0;
            }

            &:focus {
                @apply ring;
            }

            i {
                @apply text-2xl mb-3;
            }
        }
    }

    .info {
        @apply flex p-4;
        height: 400px;

        .editor {
            @apply flex flex-col flex-1 overflow-y-auto;

            b {
                @apply mt-6;
            }

            .field {
                @apply flex flex-col flex-shrink-0;

                &:not(:first-of-type) {
                    @apply mt-4;
                }

                label {
                    @apply mb-1 font-bold;
                }
            }

            select {
                @apply rounded-md py-2;
                border: 1px solid #dcdde1;
            }
        }

        .preview {
            @apply relative ml-6 p-2 w-1/2 bg-gray-200 border border-gray-400;

            h2 {
                @apply mb-2 text-gray-500;
            }
        }
    }

    .update-btn {
        height: 24px;
    }
}
</style>
