<template>
    <page id="dashboard" :infinite-scroll-handler="handleInfiniteScroll">
        <dashboard-scans ref="dashboardScans" />
    </page>
</template>

<script>
import DashboardScans from './dashboard/f-scans';

export default {
    components: { DashboardScans },

    methods: {
        handleInfiniteScroll() {
            this.$refs.dashboardScans.handleInfiniteScroll();
        }
    }
};
</script>

<style lang="scss">
#dashboard {
    .stats {
        @apply flex mb-2;

        > div {
            @apply flex-1 mr-2 p-2 rounded-md text-lg bg-gray-400;

            &:last-of-type {
                @apply mr-0;
            }
        }
    }
}
</style>
