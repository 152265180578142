<template>
    <page id="setup" no-nav>
        <form @submit.prevent="setup">
            <div class="instructions">
                Thank you for installing the Sync Scan server. Please complete the form below to license the software and set up your administrator
                account.
            </div>

            <label>License</label>
            <textarea
                v-model="licenseBlob"
                placeholder="Please paste in the license you were provided by Sync City."
                class="license"
                :class="{ invalid: isLicenseValid === false }"
                spellcheck="false"
                @blur="validateLicense"
                v-autofocus
                required
            />
            <p v-if="isLicenseValid === false" class="invalid">
                This license does not appear to be valid. Please contact Sync City if you need further assistance.
            </p>

            <label>Organization</label>
            <input v-model="orgName" type="text" class="org" placeholder="Acme Corporation" required />
            <p>This name will appear on the kiosks and in alert and enrollment request e-mails and texts.</p>

            <label>Your Name</label>
            <div class="name">
                <input v-model="firstName" type="text" placeholder="First" required />
                <input v-model="lastName" type="text" placeholder="Last" required />
            </div>

            <label>E-mail Address</label>
            <input v-model="email" type="email" placeholder="user@domain.com" required />

            <label>Password</label>
            <div class="password">
                <input v-model="password" type="password" placeholder="create new password" required />
                <input v-model="passwordConfirm" type="password" placeholder="confirm new password" required />
            </div>

            <label>Server IP Address</label>
            <input v-model="serverIp" type="text" placeholder="1.2.3.4" required />
            <p>This is the IP kiosks will use to connect to your server. Ensure it's accessible from the subnet the kiosks are attached to.</p>

            <button class="btn-primary">Save &amp; Continue to Settings</button>
        </form>
    </page>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            licenseBlob: null,
            isLicenseValid: null,
            orgName: null,
            firstName: null,
            lastName: null,
            email: null,
            password: null,
            passwordConfirm: null,
            serverIp: null
        };
    },

    mounted() {
        if (this.session.isServerConfigured !== false) {
            return this.$router.replace('/');
        }

        this.getIp();
    },

    methods: {
        async getIp() {
            const result = await this.$http.get('/api/setup/default-ip');
            if (this.serverIp === null) this.serverIp = result.data.serverIp;
        },

        async validateLicense() {
            if (!this.licenseBlob) return;

            try {
                await this.$http.post('/api/setup/check-license', {
                    license: this.licenseBlob
                });
                this.isLicenseValid = true;
            } catch (err) {
                if (err.code === 'USERERR') this.isLicenseValid = false;
                else this.isLicenseValid = null;
            }
        },

        async setup() {
            this.$wait();

            try {
                if (this.password.length < 8) this.$throwUserError('Passwords must be at least 8 characters long.');
                if (this.passwordConfirm != this.password) this.$throwUserError('The passwords provided do not match.');
                if (!/^[1-9][0-9]*\.[0-9]+\.[0-9]+\.[0-9]+$/.test(this.serverIp)) this.$throwUserError('Please enter a valid IP address.');

                await this.$http.post('/api/setup', {
                    license: this.licenseBlob,
                    orgName: this.orgName,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    serverIp: this.serverIp
                });

                const sessionResponse = await this.$http.get('/api/me');
                this.session = this.$app.setSession(sessionResponse.data);

                this.$router.replace('/settings');
            } catch (err) {
                this.$alert('Setup', err);
            } finally {
                this.$endWait();
            }
        }
    }
};
</script>

<style lang="scss">
#setup {
    label {
        @apply block mt-5 mb-1 text-gray-700;
    }

    p {
        @apply mt-1 text-gray-500;
    }

    .license {
        @apply w-1/2 h-32 resize-none;

        &.invalid {
            @apply border-red-700;
        }
    }

    p.invalid {
        @apply text-red-700;
    }

    .org {
        @apply w-1/4;
    }

    .name {
        input {
            @apply w-40;
        }

        input:not(:first-child) {
            @apply ml-1;
        }
    }

    input[type='email'] {
        @apply w-64;
    }

    .password {
        input {
            @apply block w-64;
        }

        input:not(:first-child) {
            @apply mt-1;
        }
    }

    button {
        @apply mt-8;
    }
}
</style>
