<template>
    <modal id="device-settings" closeOnMaskClick closeX>
        <h1 slot="header">Device Info</h1>

        <div class="details">
            <div>
                <span><strong>MAC</strong> {{ device.mac | mac }}</span>
                <span>
                    <template v-if="device.softwareUpdateStatus"><strong>Software Version</strong> {{ device.softwareUpdateStatus }}</template>
                    <template v-else>
                        <strong>Software Version</strong> {{ device.softwareVersion | version }}
                        <button
                            v-if="device.softwareVersion < session.swVersionDeviceLatest && device.isConnected"
                            class="btn-primary update-btn"
                            @click.prevent="$modalOpener.updateSoftware"
                        >
                            Update
                        </button>
                    </template>
                </span>
            </div>
            <div>
                <span><strong>LAN IP</strong> {{ device.lanIp | dash }}</span>
                <span v-if="session.isCloudServer"><strong>Internet IP</strong> {{ device.internetIp | dash }}</span>
            </div>
        </div>

        <div class="header">
            <div>Device Settings</div>
        </div>

        <form class="settings">
            <div class="setting">
                <span class="font-bold"
                    >Temperature adjustment
                    <i
                        class="fa fa-question-circle"
                        v-tip="
                            `In very hot or cold environments, it may be necessary to offset the temperature reading to compensate for the variability of skin surface temperature and/or excessive heating or cooling of the temperature sensor itself.`
                        "
                /></span>

                <label class="ml-auto"
                    ><input
                        type="number"
                        v-model.number="settings.tempAdjustmentOffset"
                        step="0.1"
                        v-tip="`Leave blank to follow the organizational device settings`"
                    />
                    &deg;F</label
                >
            </div>

            <div class="setting">
                <span class="font-bold">Volume level</span>

                <label class="ml-auto">
                    <div class="select-wrap">
                        <select v-model="settings.volume" v-tip="`Leave blank to follow the organizational device settings`">
                            <option :value="null">-</option>
                            <option v-for="v in 15" :key="v" :value="v">{{ v }}</option>
                        </select>
                    </div>
                </label>
            </div>

            <button :disabled="!haveSettingsChanged" class="btn-primary w-1/4 ml-auto" @click="save">Save Changes</button>
        </form>
    </modal>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            device: null,
            haveSettingsChanged: false,
            settings: {
                tempAdjustmentOffset: null,
                volume: null
            }
        };
    },

    watch: {
        settings: {
            deep: true,
            handler() {
                this.haveSettingsChanged = true;
            }
        }
    },

    mounted() {
        Object.assign(this.settings, this.device.config);
    },

    methods: {
        async save() {
            this.$wait();

            this.settings.tempAdjustmentOffset = this.settings.tempAdjustmentOffset || null;

            try {
                await this.$http.post(`/api/orgs/:orgId/devices/${this.device.mac}/config`, this.settings);
                this.haveSettingsChanged = false;
            } catch (err) {
                this.$alert('Save Changes', err);
            } finally {
                this.$endWait();
            }
        }
    }
};
</script>

<style lang="scss">
#device-settings {
    .vf-modal-content {
        @apply bg-gray-100;
        min-width: 500px;
        max-width: 1000px;
    }

    .details {
        @apply flex p-4;

        > div {
            @apply flex-1 flex flex-col;

            &:not(:first-child) {
                @apply ml-4;
            }
        }

        span {
            @apply flex-1 flex items-center bg-white p-2 rounded-md shadow-sm;

            &:not(:first-child) {
                @apply mt-4;
            }
        }

        strong {
            @apply mr-auto;
        }

        .update-btn {
            @apply ml-2;
            height: 24px;
        }
    }

    .header {
        @apply flex items-center w-full text-white px-2;
        background: #343a40;
        min-height: 34px;
    }

    .settings {
        @apply flex flex-col p-4;

        .setting {
            @apply flex-1 flex items-center justify-center bg-white mb-4 p-2 rounded-md shadow-sm;
            min-height: 60px;

            &[disabled] {
                @apply text-gray-500;
            }

            label {
                @apply ml-auto;
            }

            input[type='number'] {
                width: 50px;
            }
        }
    }
}
</style>
