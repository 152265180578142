<template>
    <modal id="view-person" closeOnMaskClick closeX>
        <h1 slot="header">View Person</h1>

        <div class="info">
            <div class="photo">
                <img v-if="person.isFaceRegistered" ref="photoImg" class="photo" :src="photoSrc" />
                <template v-else>
                    <i class="fas fa-user-alt" />
                </template>
            </div>

            <div class="ml-2 flex-1 flex flex-col">
                <div class="flex-1 flex">
                    <div class="flex-1">
                        <div class="text-2xl">{{ person.lastName }}, {{ person.firstName }}</div>
                        <div v-if="person.customIdentifier"><b>ID:</b> {{ person.customIdentifier }}</div>
                        <a :href="`mailto:${person.email}`">{{ person.email }}</a>
                        <div>{{ person.phone }}</div>
                    </div>

                    <div>
                        <span class="ml-auto text-xl">{{ group.name }}</span>
                    </div>
                </div>

                <div class="flex">
                    <div v-if="person.nfcId" class="status nfc">NFC</div>
                    <div v-if="person.isBanned" class="status banned">Banned</div>

                    <div class="ml-auto">
                        <template v-if="session.isProProduct">
                            <button class="btn-default mr-2 send p-0">
                                <div class="p-2">Send <i class="fa fa-caret-down ml-1" /></div>
                                <ul class="send-menu">
                                    <li @click="send($event, 'enrollment')">Face enrollment request</li>
                                    <li @click="send($event, 'mobile-code')">Mobile identity barcode</li>
                                    <li @click="send($event, 'wellness-q')">Wellness questionnaire</li>
                                </ul>
                            </button>
                        </template>

                        <button class="btn-default" @click="editPerson"><i class="fa fa-pencil mr-1" /> Edit</button>
                    </div>
                </div>
            </div>
        </div>

        <scans class="in-modal" :scans="scans" :person="person" :loading="isLoadingScans" @load-more="loadMoreScans" />
    </modal>
</template>

<script>
import Scans from '@/common/fragments/scans';
import EditPersonModal from './m-edit';

export default {
    store: ['session'],
    components: { Scans },

    data() {
        return {
            photoSrc: null,
            photoFile: null,
            view: 'grid',
            scans: null,
            isLoadingScans: false,
            canLoadMoreScans: true
        };
    },

    created() {
        if (this.person.isFaceRegistered) {
            this.photoSrc = `/api/orgs/${this.session.activeOrg.uuid}/people/${this.person.uuid}/photo?v=${this.person.photoVersion}`;
        }

        this.$ws.addEventListener('scans', this.handleDeviceScan);
    },

    mounted() {
        this.loadScans();
    },

    beforeDestroy() {
        this.$ws.removeEventListener('scans', this.handleDeviceScan);
    },

    methods: {
        async loadScans() {
            const response = await this.$http.get('/api/orgs/:orgId/scans?personId=' + this.person.uuid);
            this.scans = response.data;
        },

        async loadMoreScans() {
            if (!this.canLoadMoreScans) return;
            if (!this.scans.length) return;
            this.isLoadingScans = true;
            const response = await this.$http.get('/api/orgs/:orgId/scans', {
                params: {
                    personId: this.person.uuid,
                    beforeId: this.scans.last().id
                }
            });
            this.scans.push.apply(this.scans, response.data);
            this.canLoadMoreScans = response.data.length > 0;
            this.isLoadingScans = false;
        },

        async send(e, item) {
            this.$wait();

            try {
                await this.$http.post('/api/orgs/:orgId/people/' + this.person.uuid + '/send-' + item);
                this.$alert(
                    'Send ' + e.target.innerText.ucwords(),
                    'The ' + e.target.innerText.toLowerCase() + ' has been sent to ' + this.person.firstName + '.'
                );
            } catch (err) {
                this.$alert('Send ' + e.target.innerText.ucwords(), err);
            }

            this.$endWait();
        },

        async editPerson() {
            const updatedPerson = await this.$modal(EditPersonModal, { person: this.person, group: this.group });
            if (!updatedPerson) return;

            Object.assign(this.person, updatedPerson);
        },

        async handleDeviceScan(e) {
            if (!e.data.people[this.person.uuid]) return;
            this.loadScans();
        }
    }
};
</script>

<style lang="scss">
#view-person {
    .vf-modal {
        @apply min-h-0;
        min-width: 700px;
        max-width: 800px;
    }

    .vf-modal-content {
        @apply min-h-0 flex flex-col;
    }

    .info {
        @apply flex flex-shrink-0 p-4;

        .photo {
            @apply flex flex-col items-center justify-center object-cover h-48 w-48 rounded-lg bg-gray-300;

            i {
                @apply mb-2 text-4xl;
            }
        }

        a {
            color: $button-primary-color;
        }

        .status {
            @apply border-2 rounded-md flex items-center p-1 text-xl border-gray-300 bg-gray-100;

            &:not(:first-child) {
                @apply ml-1;
            }

            &.banned {
                @apply border-red-300 bg-red-100;
            }
        }

        button.send {
            .send-menu {
                @apply hidden absolute rounded-md z-50 mt-0.5;
                background: $button-default-color;

                li {
                    @apply p-2 cursor-pointer;

                    &:hover {
                        background-color: darken($button-default-color, 10%);
                    }
                }
            }

            &:hover .send-menu {
                @apply block;
            }
        }
    }
}
</style>
