export { convertFromWiegand, convertToWiegand };

function convertFromWiegand(value) {
    value = swap32(value);
    value = Number(value).toString(16);
    value = value.toUpperCase();
    value = value.replace(/..\B/g, '$&:');
    value = value.padStart(11, '0');
    return value;
}

function convertToWiegand(value) {
    value = value
        .trim()
        .toLowerCase()
        .replace(/[^0-9a-f]/g, '')
        .substr(-8);

    if (value.length < 8) throw new Error('wiegand hex too short');

    value = parseInt(value, 16);
    value = swap32(value);
    value = value >>> 0;
    return value;
}

function swap32(val) {
    return ((val & 0xff) << 24) | ((val & 0xff00) << 8) | ((val >> 8) & 0xff00) | ((val >> 24) & 0xff);
}
