<template>
    <div id="report-settings">
        <div v-if="!session.isProProduct" class="pro-alert">
            The below options are Pro features.<br />Please contact your sales representative for more information.
        </div>

        <div class="container relative" :class="{ disabled: !session.isProProduct }">
            <div class="setting">
                <span class="font-bold">Receive daily scans report</span>

                <label class="toggle ml-auto">
                    <input type="checkbox" v-model="settings.shouldSendDailyScansReport" v-disabled="!session.isProProduct" />
                    <div />
                </label>
            </div>

            <!--<div class="setting">
                <span class="font-bold">Receive daily attendance report</span>

                <label class="toggle ml-auto">
                    <input type="checkbox" v-model="settings.shouldSendDailyAttendanceReport" v-disabled="!session.isProProduct" />
                    <div />
                </label>
            </div>-->

            <div class="report-destinations">
                <span class="block font-bold my-2">Report destinations</span>

                <div v-for="(entry, index) in settings.reportDestinations" :key="index" class="row">
                    <input v-model="entry.address" type="email" placeholder="______@_____.____" v-autofocus="!entry.address" />
                    <i class="fa fa-times remove" @click="removeReportDestination(entry)" />
                </div>

                <a href="#" @click.prevent="addReportDestination">+ Add another</a>
            </div>

            <div class="flex">
                <button :disabled="!haveSettingsChanged" class="btn-primary ml-auto" @click="save">Save Changes</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            haveSettingsChanged: false,

            settings: {
                shouldSendDailyScansReport: null,
                shouldSendDailyAttendanceReport: null,
                reportDestinations: []
            }
        };
    },

    watch: {
        session: {
            deep: true,
            handler() {
                this.load();
            }
        },

        settings: {
            deep: true,
            handler() {
                this.haveSettingsChanged = true;
            }
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.$wait();

            const response = await this.$http.get('/api/orgs/:orgId/config?keys=' + Object.keys(this.settings).join(','));
            this.settings = response.data;

            this.$endWait();

            this.$nextTick(() => {
                this.haveSettingsChanged = false;
            });
        },

        addReportDestination() {
            if (!this.session.isProProduct) return;
            this.settings.reportDestinations.push({ type: 'email', address: '' });
        },

        removeReportDestination(entry) {
            this.settings.reportDestinations.remove(entry);
        },

        async save() {
            for (let i = 0; i < this.settings.reportDestinations.length; i++) {
                if (!this.settings.reportDestinations[i].address) {
                    this.settings.reportDestinations.splice(i, 1);
                    continue;
                }

                if (this.settings.reportDestinations[i].type === 'email') {
                    if (!/^.+@.+\..+$/.test(this.settings.reportDestinations[i].address))
                        return this.$alert('Save', '"' + this.settings.reportDestinations[i].address + '" is not a valid email.');
                }
            }

            this.$wait();

            try {
                await this.$http.post('/api/orgs/:orgId/config', this.settings);
                this.haveSettingsChanged = false;
            } catch (err) {
                this.$alert('Save Changes', err);
            } finally {
                this.$endWait();
            }
        }
    }
};
</script>

<style lang="scss">
#report-settings {
    .pro-alert {
        @apply text-center text-lg p-12 font-bold;
    }

    .disabled {
        opacity: 0.5;
    }

    .container {
        @apply max-w-4xl mx-auto;
    }

    .setting {
        @apply flex items-center justify-center flex-wrap bg-white mb-4 p-4 rounded-md shadow-sm;
        min-height: 60px;

        .inputs-container {
            @apply flex ml-auto items-center;
            min-height: 34px;

            input {
                @apply relative mr-2;
                width: 46px;
            }
        }
    }

    .report-destinations {
        @apply flex-col bg-white rounded-md shadow-sm mb-4 p-4;

        .row {
            @apply flex items-center my-4;

            select {
                @apply pr-8;
            }

            input[type='tel'],
            input[type='email'] {
                @apply ml-2 w-1/3;
            }

            .remove {
                @apply ml-2 text-gray-500 hidden cursor-pointer;

                &:hover {
                    @apply text-gray-800;
                }
            }

            &:hover {
                .remove {
                    display: inherit;
                }
            }
        }

        a {
            color: $button-primary-color;
        }
    }
}
</style>
