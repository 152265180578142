<template>
    <div>
        <div v-if="!scans" class="status">Loading...</div>
        <div v-else-if="!scans.length" class="status">Scans will begin to appear here as they occur.</div>

        <template v-else>
            <div class="top-filters flex">
                <button
                    v-for="(collection, key) in collections"
                    :key="key"
                    class="mr-2"
                    :class="filter == key ? 'btn-primary' : 'btn-default'"
                    @click="setFilter(key)"
                >
                    <span>{{ key.ucfirst() }}: {{ collections[key].length }}</span>
                </button>
            </div>
            <scans :scans="renderedScans" :loading="isLoading" />
        </template>
    </div>
</template>

<script>
import Scans from '@/common/fragments/scans';

export default {
    store: ['session'],

    components: { Scans },

    data() {
        return {
            isLoading: false,
            canLoadMore: true,
            scans: null,
            filter: 'all'
        };
    },

    computed: {
        orgId() {
            return this.session.activeOrg.uuid;
        },

        collections() {
            return {
                all: this.scans,
                known: this.scans.filter(scan => scan.person && !scan.person.isVisitor),
                strangers: this.scans.filter(scan => !scan.person && !scan.personId),
                allowed: this.scans.filter(scan => scan.result == 'allowed'),
                denied: this.scans.filter(scan => scan.result != 'allowed')
            };
        },

        renderedScans() {
            return this.collections[this.filter];
        }
    },

    watch: {
        session: {
            deep: true,
            handler() {
                this.load();
            }
        }
    },

    created() {
        this.$ws.addEventListener('scans', this.handleNewScan);
    },

    beforeDestroy() {
        this.$ws.removeEventListener('scans', this.handleNewScan);
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            const response = await this.$http.get('/api/orgs/:orgId/scans');
            this.scans = response.data;
        },

        setFilter(filter) {
            this.filter = filter;
        },

        async handleNewScan(e) {
            const response = await this.$http.get('/api/orgs/:orgId/scans/' + e.data.scanIds[0]);
            this.scans.unshift(response.data);
        },

        async handleInfiniteScroll() {
            if (!this.canLoadMore) return;
            if (!this.scans.length) return;
            if (!this.session.isProProduct) return;
            this.isLoading = true;
            const response = await this.$http.get('/api/orgs/:orgId/scans', {
                params: {
                    beforeId: this.scans.last().id
                }
            });
            this.scans.push.apply(this.scans, response.data);
            this.canLoadMore = response.data.length > 0;
            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
.status {
    @apply flex justify-center items-center text-xl text-gray-600 p-8;
}
</style>
