<template>
    <div id="wqfb">
        <div v-if="!fields.length">You've not yet added any fields.</div>

        <form v-else class="wqfb-fields">
            <draggable v-model="fields">
                <div
                    v-for="(field, fieldIndex) in fields"
                    :key="fieldIndex"
                    class="field"
                    :class="['field-' + field.type, 'audience-' + field.audience]"
                    @dblclick="editField(field)"
                    @contextmenu.prevent="showContextMenu($event, field)"
                >
                    <label class="prompt">{{ field.prompt || 'Prompt' }}</label>

                    <select v-if="field.type == 'select'" class="single-input">
                        <option v-for="(option, optionIndex) in field.options" :key="optionIndex">{{ option }}</option>
                    </select>

                    <div v-else-if="isMultiOption(field.type)" class="multi-input-container">
                        <label v-for="(option, optionIndex) in field.options" :key="optionIndex" class="clickable-label">
                            <input :type="field.type" :name="'field' + fieldIndex" :value="optionIndex" @click.prevent />
                            {{ option }}
                        </label>
                    </div>

                    <input v-else-if="isInputOption(field.type)" :type="field.type" class="single-input" :placeholder="field.placeholder" readonly />
                </div>
            </draggable>
        </form>

        <button class="btn-create mt-4" @click="addField"><i class="fas fa-plus" /> Add Field</button>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import EditFieldModal from './form-builder/m-edit-field';

export default {
    components: {
        Draggable
    },

    store: ['session'],

    data() {
        return {
            fields: []
        };
    },

    watch: {},

    methods: {
        async addField() {
            const field = await this.$modal(EditFieldModal);
            if (!field) return;
            this.fields.push(field);
        },

        async editField(field) {
            const updatedField = await this.$modal(EditFieldModal, { field });
            if (!updatedField) return;
            Object.assign(field, updatedField);
        },

        isMultiOption(type) {
            return ['radio', 'checkbox', 'select'].includes(type);
        },

        isInputOption(type) {
            return !['header', 'label'].includes(type);
        },

        showContextMenu(e, field) {
            e.target.focus();

            this.$contextMenu(e, {
                items: [
                    {
                        title: 'Edit',
                        handler: () => {
                            this.editField(field);
                        }
                    },
                    {
                        title: 'Delete',
                        handler: () => {
                            this.fields.remove(field);
                        }
                    }
                ]
            });
        }
    }
};
</script>

<style lang="scss">
#wqfb {
    max-width: 500px;

    form {
        @apply bg-gray-200 p-2 rounded-md;

        .field {
            @apply mt-1 p-2 border-l-4 pl-4;

            &.field-header {
                @apply mt-6;
            }

            &:first-child {
                @apply mt-0;
            }

            &:hover {
                @apply bg-gray-300 rounded-md;
            }

            &.audience-registered {
                @apply border-blue-300;
            }

            &.audience-strangers {
                @apply border-yellow-300;
            }

            &.audience-both {
                @apply border-gray-300;
            }
        }
    }
}

.wqfb-fields {
    .field {
        @apply flex flex-col;
    }

    select {
        @apply rounded-md py-2;
        border: 1px solid #dcdde1;
    }

    label {
        &.prompt {
            @apply font-bold;
        }

        &.clickable-label {
            @apply flex items-center;

            input {
                @apply mr-2;
            }
        }
    }

    .single-input {
        @apply mt-1;
    }

    .multi-input-container {
        label {
            @apply mt-2;
        }
    }

    .field-header label.prompt {
        font-size: 14px;
    }

    .field-label label.prompt {
        font-weight: normal;
    }
}
</style>
