<template>
    <table>
        <thead>
            <th>Group</th>
            <th>Person</th>
            <th v-for="date in dates" :key="date" v-datetime="date" format="MM/DD/YY" local />
        </thead>
        <tbody>
            <tr v-for="(person, index) in data" :key="index">
                <td>{{ person.groupName }}</td>
                <td>{{ person.lastName }}, {{ person.firstName }}</td>
                <td v-for="date in dates" :key="index + ':' + date">
                    <template v-if="person.dates && person.dates[date]">{{ person.dates[date] }}</template>
                    <template v-else>&mdash;</template>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import moment from 'moment';

export default {
    props: ['filters', 'data', 'startDate', 'endDate'],

    computed: {
        dates() {
            let aMoment = moment(this.startDate);
            let endMoment = moment(this.endDate);

            let result = [];
            while (endMoment >= aMoment) {
                result.push(aMoment.format('YYYY-MM-DD'));
                aMoment.add(1, 'day');
            }
            return result;
        }
    }
};
</script>

<style></style>
