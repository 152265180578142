export default {
    translateStatus(rawStatus) {
        const statusCode = rawStatus.substr(0, 2);
        if (statusCode == 'DS') return 'Update: Download started';
        if (statusCode == 'DP') return 'Update: Downloaded ' + rawStatus.substr(2) + '%';
        if (statusCode == 'DF') return 'Update: Download failed';
        if (statusCode == 'IS') return 'Update: Installation started';
        return 'Updating...';
    }
};
