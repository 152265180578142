<template>
    <modal>
        <h1 slot="header">Change Password</h1>

        <input v-model="password" type="password" placeholder="new password" v-autofocus />
        <input v-model="passwordConfirm" type="password" placeholder="confirm password" />

        <div slot="footer">
            <button class="btn-primary" @click="change" v-disabled="!password">Change</button>
            <button class="btn-default" @click="$dismiss()">Cancel</button>
        </div>
    </modal>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            password: null,
            passwordConfirm: null
        };
    },

    methods: {
        async change() {
            try {
                if (this.password.length < 8) this.$throwUserError('Passwords must be at least 8 characters long.');
                if (this.passwordConfirm != this.password) this.$throwUserError('The passwords provided do not match.');

                this.$maskForm('.btn-primary');

                await this.$http.put('/api/me', {
                    password: this.password
                });

                this.$dismiss();
            } catch (err) {
                this.$unmaskForm();
                this.$alert('Change Password', err);
            }
        }
    }
};
</script>

<style style="scss" scoped>
input {
    @apply block m-2 w-64;
}
</style>
