<template>
    <modal id="upload" scrolls>
        <h1 slot="header">Bulk Upload</h1>

        <div class="flex flex-shrink-0 justify-center p-2">
            <v-switcher v-model="component">
                <button value="identities">Identities</button>
                <button value="photos">Photos</button>
            </v-switcher>
        </div>

        <component class="component" ref="component" :is="component" @ready="v => (isReady = v)" />

        <div slot="footer">
            <template v-if="component == 'identities'">
                <button class="btn-primary" @click="upload" v-disabled="!isReady">Upload</button>
                <button class="btn-default" @click.prevent="$dismiss()">Cancel</button>
            </template>
            <template v-else>
                <button class="btn-default" @click.prevent="$dismiss(true)" v-disabled="!isReady">Done</button>
            </template>
        </div>
    </modal>
</template>

<script>
import Identities from './m-upload/f-identities';
import Photos from './m-upload/f-photos';

export default {
    components: {
        Identities,
        Photos
    },

    data() {
        return {
            component: 'identities',
            isReady: false
        };
    },

    methods: {
        async upload() {
            this.$maskForm('.btn-primary');

            try {
                await this.$refs.component.upload();
                await this.$dismiss(true);
            } catch (err) {
                this.$unmaskForm();
                this.$alert('Bulk Upload', err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vf-modal {
        height: 500px;
    }

    .vf-modal-content {
        @apply flex-1 flex flex-col min-h-0;
    }

    .component {
        @apply flex-1;
    }
}
</style>
