<template>
    <page id="reports">
        <div class="filters">
            <label>Report:</label>
            <div class="select-wrap">
                <select v-if="!reports" disabled>
                    <option>Loading...</option>
                </select>
                <select v-else v-model="selectedReport">
                    <option :value="null" disabled selected>Select a report...</option>
                    <option v-for="report in reports" :key="report.key" :value="report">{{ report.title }}</option>
                </select>
            </div>

            <template v-if="shouldShowNonDateFilters">
                <label>Group:</label>
                <smart-select v-model="filters.groupId" :options="groups" value-key="uuid" nullTitle="All Groups" />

                <label>Device:</label>
                <smart-select v-model="filters.deviceId" :options="devices" value-key="uuid" nullTitle="All Devices" />
            </template>

            <template v-if="shouldShowDateFilters">
                <label>Date Range:</label>
                <input v-model="filters.startDate" type="date" /> -
                <input v-model="filters.endDate" type="date" />
            </template>

            <button class="ml-auto btn-primary" v-disabled="!reportData" @click="downloadReport" v-tip="'Download as Excel'">
                <i class="fa fa-download" />
            </button>
        </div>

        <template v-if="selectedReport">
            <component
                ref="reportCmp"
                v-if="reportData || selectedReport.standardData === false"
                :is="selectedReport.key"
                :filters="filters"
                :data="reportData"
                :start-date="filters.startDate"
                :end-date="filters.endDate"
                class="report"
            />
            <div v-else-if="isReportProFeature" class="large-loading">
                This report is a Pro feature. Please contact your sales representative for more information.
            </div>
            <div v-else class="large-loading">Loading...</div>
        </template>
        <div v-else class="large-loading">Select a report.</div>
    </page>
</template>

<script>
import moment from 'moment';
import tableToExcel from '@/common/helpers/table-to-excel';
import Scans from './reports/f-scans';
import Attendance from './reports/f-attendance';
import WellnessQ from './reports/f-wellness-q';
import CustomPf1 from './reports/f-custom-pf1';

export default {
    store: ['session'],

    components: {
        Scans,
        Attendance,
        WellnessQ,
        CustomPf1
    },

    data() {
        return {
            reports: null,
            selectedReport: null,
            filters: {
                groupId: null,
                deviceId: null,
                startDate: null,
                endDate: null
            },
            isReportProFeature: null,
            reportData: null
        };
    },

    computed: {
        shouldShowNonDateFilters() {
            return !this.selectedReport || (this.selectedReport.showFilters !== false && this.selectedReport.showNonDateFilters !== false);
        },

        shouldShowDateFilters() {
            return !this.selectedReport || this.selectedReport.showFilters !== false;
        },

        groups() {
            let arr = [];
            for (let uuid in this.session.activeOrg.groups) {
                arr.push({ uuid, name: this.session.activeOrg.groups[uuid] });
            }
            return arr;
        },

        devices() {
            let arr = [];
            for (let uuid in this.session.activeOrg.devices) {
                arr.push({ uuid, name: this.session.activeOrg.devices[uuid] });
            }
            return arr;
        },

        reportUrl() {
            const startISOTime = moment(this.filters.startDate).toISOString();
            const endISOTime = moment(this.filters.endDate).endOf('day').toISOString();

            let url = `/api/orgs/${this.session.activeOrg.uuid}/reports/${this.selectedReport.key}?start=${startISOTime}&end=${endISOTime}`;
            if (this.filters.groupId) url += '&groupId=' + this.filters.groupId;
            if (this.filters.deviceId) url += '&deviceId=' + this.filters.deviceId;
            return url;
        }
    },

    watch: {
        session() {
            this.loadReports();
        },

        selectedReport() {
            this.loadReportData();
        },

        filters: {
            deep: true,
            handler() {
                this.selectedReport && this.loadReportData();
            }
        }
    },

    created() {
        this.filters.startDate = moment().subtract(1, 'week').add(1, 'day').format('YYYY-MM-DD');
        this.filters.endDate = moment().format('YYYY-MM-DD');
        this.loadReports();
    },

    methods: {
        async loadReports() {
            const response = await this.$http.get('/api/orgs/:orgId/reports');
            this.reports = response.data;
        },

        async loadReportData() {
            this.reportData = null;

            if (this.selectedReport.standardData === false) return;

            try {
                const response = await this.$http.get(this.reportUrl);
                this.isReportProFeature = false;
                this.reportData = response.data;
            } catch (err) {
                if (err.response?.status == 402) {
                    this.isReportProFeature = true;
                    this.reportData = null;
                } else {
                    throw err;
                }
            }
        },

        downloadReport() {
            const reportName = this.selectedReport.title;
            const fileName = reportName + ' Report.xlsx';
            tableToExcel(fileName, reportName, this.$refs.reportCmp.$el);
        }
    }
};
</script>

<style lang="scss">
#reports {
    .filters {
        @apply flex items-center;

        label {
            @apply mr-2;

            &:not(:first-child) {
                @apply ml-4;
            }
        }

        input[type='date'] {
            @apply mx-1;
            width: 140px;
        }
    }

    .large-loading {
        @apply py-32;
    }
}
</style>
