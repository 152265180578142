<template>
    <page id="settings">
        <div class="tabs">
            <router-link v-for="(tab, index) in tabs" :key="index" :to="`/settings/${typeof tab === 'object' ? tab.route : tab}`">{{
                (typeof tab === 'object' ? tab.title : tab).ucfirst()
            }}</router-link>
        </div>
        <router-view class="flex-1 ml-4" />
    </page>
</template>

<script>
import Device from './settings/f-device';
import Alerts from './settings/f-alerts';
import Reports from './settings/f-reports';
import WellnessQ from './settings/f-wellness-q';
import Server from './settings/f-server';
import Users from './settings/f-users';

export default {
    store: ['session'],

    childRoutes: [
        {
            path: '',
            redirect: 'device'
        },
        {
            path: 'device',
            component: Device
        },
        {
            path: 'alerts',
            component: Alerts
        },
        {
            path: 'Reports',
            component: Reports
        },
        {
            path: 'wellness-q',
            component: WellnessQ
        },
        {
            path: 'server',
            component: Server
        },
        {
            path: 'users',
            component: Users
        }
    ],

    data() {
        return {
            tabs: []
        };
    },

    watch: {
        session: {
            deep: true,
            handler() {
                this.setTabs();
            }
        }
    },

    created() {
        this.setTabs();
    },

    methods: {
        setTabs() {
            let tabs = ['device', 'alerts', 'reports', { route: 'wellness-q', title: 'Wellness Questionnaire' }];

            if (this.session.isSuperUser || this.session.isOrgAdmin) {
                this.session.isCloudServer || tabs.push('server');
                tabs.push('users');
            }

            this.tabs = tabs;
        }
    }
};
</script>

<style lang="scss">
#settings {
    #root-content .inner-wrap {
        flex-direction: row !important;
    }

    .tabs {
        min-width: 200px;

        a {
            @apply block p-4 mb-2 rounded-md cursor-pointer;

            &:hover {
                @apply bg-gray-300;
            }

            &.router-link-active {
                @apply text-white;
                background: $button-primary-color;
                background: #2d3436;

                &:hover {
                    background: darken($color: $button-primary-color, $amount: 10%);
                    background: lighten($color: #2d3436, $amount: 10%);
                }
            }
        }
    }

    .container {
        @apply max-w-4xl mx-auto;
    }

    .setting {
        @apply flex items-center justify-center flex-wrap bg-white mb-4 p-4 rounded-md shadow-sm;
        min-height: 60px;

        &.stacked {
            @apply flex-col;
        }

        .row {
            @apply w-full flex items-center justify-center;
        }

        .explanation {
            @apply mt-3 block text-gray-400;
        }

        .inputs-container {
            @apply flex ml-auto items-center;
            min-height: 34px;

            input {
                @apply relative mr-2;
                width: 64px;
            }
        }
    }
}
</style>
