<template>
    <modal>
        <h1 slot="header">Switch Organization</h1>

        <smart-select v-model="selectedOrg" class="m-2" placeholder="Select..." url="/api/orgs" v-autofocus />

        <div slot="footer">
            <button class="btn-primary" @click="switchOrg" v-disabled="!selectedOrg">Switch</button>
            <button class="btn-default" @click="$dismiss()">Cancel</button>
        </div>
    </modal>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            selectedOrg: null
        };
    },

    methods: {
        async switchOrg() {
            try {
                this.$maskForm('.btn-primary');

                const response = await this.$http.put('/api/me', {
                    activeOrg: this.selectedOrg.uuid
                });
                this.$app.setSession(response.data);

                this.$dismiss();
            } catch (err) {
                this.$unmaskForm();
                this.$alert('Switch Organization', err);
            }
        }
    }
};
</script>

<style></style>
