<template>
    <div id="wellness-q">
        <div v-if="!session.isProProduct" class="pro-alert">
            The wellness questionnaire is a Pro feature.<br />Please contact your sales representative for more information.
        </div>

        <template v-else>
            <h2>Access</h2>

            <p>
                The questionnaire can be accessed by the following URL. You may include it in communication with employees or visitors. A printable QR
                code has also been provided for your convenience.
            </p>

            <p>
                For registered people, you can configure specific questions below (i.e. name, email, etc) to be skipped. Then, visit the
                <strong>People</strong> tab to send them pre-linked questionnaire URLs.
            </p>

            <div class="flex p-8">
                <div class="flex-1 flex items-center justify-center">
                    <a :href="url" target="_blank" class="wq-link" v-tip="`Click to copy to clipboard`" @click="copyUrlToClipboard">{{ url }}</a>
                </div>

                <div class="flex-1 flex items-center justify-center">
                    <a :href="`/api/orgs/${session.activeOrg.uuid}/config/wellness-q/qr`">
                        <img
                            :src="`/api/orgs/${session.activeOrg.uuid}/config/wellness-q/qr`"
                            width="100"
                            height="100"
                            v-tip="`Right-click and select Copy Image or Save Image to copy or save the high resolution QR code.`"
                    /></a>
                </div>
            </div>

            <h2>Questionnaire</h2>
            <form-builder ref="formBuilder" />
            <button class="btn-primary mt-2" @click="save">Save Questionnaire</button>
        </template>
    </div>
</template>

<script>
import FormBuilder from './f-wellness-q/f-form-builder';

export default {
    components: {
        FormBuilder
    },

    store: ['session'],

    data() {
        return {
            url: null
        };
    },

    watch: {
        session: {
            deep: true,
            handler() {
                this.load();
            }
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.$wait();

            const response = await this.$http.get('/api/orgs/:orgId/config/wellness-q');
            this.url = response.data.url;
            this.$refs.formBuilder.fields = response.data.fields;

            this.$endWait();
        },

        async copyUrlToClipboard(e) {
            e.preventDefault();
            navigator.clipboard.writeText(this.url);
        },

        async save() {
            this.$wait();

            const fields = this.$refs.formBuilder.fields;
            fields.forEach(field => {
                if (field.options) {
                    field.options = field.options.filter(option => option);
                }
            });

            try {
                await this.$http.post('/api/orgs/:orgId/config/wellness-q', {
                    fields
                });
            } catch (err) {
                this.$alert('Save Questionnaire', err);
            } finally {
                this.$endWait();
            }
        }
    }
};
</script>

<style lang="scss">
#wellness-q {
    @apply max-w-4xl;

    .pro-alert {
        @apply text-center text-lg p-12 font-bold;
    }

    h2 {
        @apply text-xl font-bold;
    }

    p:not(:first-of-type) {
        @apply mt-4;
    }

    .wq-link {
        @apply text-xl text-blue-700;
    }

    .wqfb-fields {
        @apply mt-2;
    }
}
</style>
