<template>
    <div class="scans">
        <div class="header">
            <div>Scan History</div>
            <v-switcher v-model="view" class="ml-auto">
                <button value="grid">Grid</button>
                <button value="list">List</button>
            </v-switcher>
        </div>

        <div v-if="!scans" class="info">Loading...</div>
        <div v-else-if="!scans.length" class="info">No scans.</div>

        <div v-else class="scans-content" v-infinite-scroll="handleInfiniteScroll">
            <div v-if="view == 'grid'" class="grid-container">
                <scan-tile v-for="scan in scans" :key="scan.date" :scan="scan" :person="person" />
                <div v-if="loading" class="scan-tile loading">Loading...</div>
            </div>

            <table v-else class="sticky-header">
                <thead>
                    <tr>
                        <th>Photo</th>
                        <th v-if="!person">Name</th>
                        <th>Temperature</th>
                        <th>Result</th>
                        <th v-if="!device">Device</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="scan in scans" :key="scan.date" :class="{ denied: scan.result !== 'allowed' }">
                        <td class="photo-col">
                            <img v-if="scan.hasPhoto" :src="`/api/orgs/${session.activeOrg.uuid}/scans/${scan.id}/photo`" />
                        </td>
                        <td v-if="!person" class="person" :class="{ unknown: !scan.person }">
                            {{ scan.person ? `${scan.person.lastName}, ${scan.person.firstName}` : 'Unidentified' }}
                        </td>
                        <td>
                            <template v-if="scan.temperature != 0">{{ scan.temperature }}°</template>
                            <template v-else>-</template>
                        </td>
                        <td>{{ scan.result | formatResult }}</td>
                        <td v-if="!device">{{ deviceNamesById[scan.deviceId] || 'unknown device ' + scan.deviceId }}</td>
                        <td>
                            <div class="flex">
                                <div v-datetime="scan.scannedAt" format="M/D/YY h:mm:ss a" />
                                <i
                                    v-if="scan.wqId"
                                    class="ml-auto fas fa-comment-edit text-xl cursor-pointer hover:text-gray-500"
                                    @click="showWQ(scan.wqId)"
                                />
                            </div>
                        </td>
                    </tr>
                    <tr v-if="loading" class="info">
                        <td colspan="6">Loading...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import ScanTile from '@/common/components/scan-tile';
import RESULT_DESCRIPTIONS from '@/common/maps/scan-result';
import WQViewModal from '@/common/modals/wq-view';

export default {
    store: ['session'],
    props: ['loading', 'scans', 'person', 'device'],

    components: { ScanTile },

    data() {
        return {
            view: 'grid'
        };
    },

    filters: {
        formatResult(value) {
            return RESULT_DESCRIPTIONS[value];
        }
    },

    computed: {
        deviceNamesById() {
            return this.session.activeOrg.devices;
        }
    },

    methods: {
        handleInfiniteScroll() {
            if (!this.session.isProProduct) return;
            this.$emit('load-more');
        },

        showWQ(id) {
            this.$modal(WQViewModal, {
                wqId: id
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.scans {
    @apply flex flex-col min-h-0;

    &.in-modal {
        .grid-container {
            @apply p-2;
            min-width: 525px;
        }
    }

    .header {
        @apply flex items-center w-full text-white;
        background: #343a40;
        min-height: 34px;

        > div:first-child {
            @apply text-base ml-2;
        }

        .v-switcher {
            button {
                background: $button-default-color;
                border: none;
                border-radius: 0;

                &:not(:disabled) {
                    &:hover,
                    &:focus {
                        background: darken($button-default-color, 10%);
                    }
                }

                &.selected {
                    @apply text-white;
                    background: $button-primary-color;

                    &:not(:disabled) {
                        &:hover,
                        &:focus {
                            background: darken($button-primary-color, 10%);
                        }
                    }
                }
            }
        }
    }

    .info {
        @apply p-2 pt-4;
    }

    .scans-content {
        @apply flex-1 overflow-auto;
    }

    .grid-container {
        @apply flex-1 grid mt-2;
        grid-template-columns: repeat(auto-fill, minmax(164px, 1fr));
        gap: 0.5rem;
    }

    .scan-tile.loading {
        @apply bg-gray-700 text-white flex items-center justify-center rounded-md;
    }

    table {
        border-radius: 0;

        tr.denied {
            @apply text-red-700;
        }

        .photo-col {
            width: 64px;

            img {
                @apply object-cover;
                height: 48px;
                width: 48px;
            }
        }
    }
}
</style>
