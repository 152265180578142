const PING_CHAR = String.fromCharCode(12);
const PONG_CHAR = String.fromCharCode(15);

export default class WebSocketClient extends EventTarget {
    constructor() {
        super();
        this._boundOpenHandler = this._handleWsOpened.bind(this);
        this._boundMessageHandler = this._handleWsMessage.bind(this);
        this._boundCloseHandler = this._handleWsClose.bind(this);
        this._boundErrorHandler = this._handleWsError.bind(this);
    }

    setOrgId(orgId) {
        this.orgId = orgId;
        this.disconnect();
        this.connect();
    }

    connect() {
        this._resetConnectTimer();

        if (!this.orgId) return;

        this._log('connecting');
        this.ws = new WebSocket(location.protocol.replace('http', 'ws') + '//' + location.host + '/ws/cp?org=' + this.orgId);
        this.ws.addEventListener('open', this._boundOpenHandler);
        this.ws.addEventListener('message', this._boundMessageHandler);
        this.ws.addEventListener('close', this._boundCloseHandler);
        this.ws.addEventListener('error', this._boundErrorHandler);
    }

    disconnect() {
        if (!this.ws) return;
        this._log('disconnecting');
        this.ws.removeEventListener('open', this._boundOpenHandler);
        this.ws.removeEventListener('message', this._boundMessageHandler);
        this.ws.removeEventListener('close', this._boundCloseHandler);
        this.ws.removeEventListener('error', this._boundErrorHandler);
        this.ws.close();
        this.ws = null;
        this._handleDisconnected();
        this._resetConnectTimer();
        this._resetPingInterval();
    }

    ping() {
        this._log('ping');
        this.ws.send(PING_CHAR);
    }

    _resetConnectTimer() {
        if (!this._connectTimer) return;
        clearTimeout(this._connectTimer);
        this._connectTimer = null;
    }

    _resetPingInterval() {
        if (!this._pingInterval) return;
        clearInterval(this._pingInterval);
        this._pingInterval = null;
    }

    _handleWsOpened(e) {
        this._log('connected', e);
        this._pingInterval = setInterval(this.ping.bind(this), 180000);

        this.isConnected = true;
        this._dispatchEvent('connected');
    }

    _handleWsMessage(e) {
        if (e.data === PONG_CHAR) return this._log('pong');
        this._log('message', e);

        const json = JSON.parse(e.data);
        if (!json.event) return this._log('unexpected payload');

        this._dispatchEvent(json.event, json);
    }

    _handleWsClose(e) {
        this._log('closed', e);
        this._resetPingInterval();
        this._connectTimer = setTimeout(this.connect.bind(this), 5000);
        this._handleDisconnected();
    }

    _handleWsError(e) {
        this._log('error', e);
    }

    _handleDisconnected() {
        if (!this.isConnected) return;
        this.isConnected = false;
        this._dispatchEvent('disconnected');
    }

    _log(...args) {
        args.unshift('[ws]');
        console.log.apply(console, args);
    }

    _dispatchEvent(name, data) {
        let event = new Event(name);
        if (data) event.data = data;
        this.dispatchEvent(event);
    }
}
