<template>
    <modal>
        <h1 slot="header">License Key for {{ org.name }}</h1>

        <textarea v-model="license" spellcheck="false" v-autofocus @focus="selectAll" />

        <div slot="footer">
            <button class="btn-default" @click.prevent="$dismiss()">Close</button>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            org: null,
            license: null
        };
    },

    created() {
        this.license = this.license.replace(/(.{64})/g, '$1\n');
    },

    methods: {
        selectAll(e) {
            e.target.select();
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vf-modal-content {
        @apply p-2;
    }
}

textarea {
    @apply p-0 border-none resize-none font-mono;
    width: 475px;
    height: 200px;
}
</style>
