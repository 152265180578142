<template>
    <table>
        <thead>
            <th>Submission Date/Time</th>
            <th>Scan Date/Time</th>
            <th>Person</th>
            <th>Device</th>
            <th>Temperature</th>
            <th>Mask</th>
            <th>Scan Result</th>
        </thead>
        <tbody>
            <tr v-for="(row, index) in data" :key="index" @click="showWQ(row)">
                <td v-datetime="row.submittedAt" format="M/D/YY h:mm:ss a" />
                <td v-datetime="row.scannedAt" format="M/D/YY h:mm:ss a" placeholder="-" />
                <td>
                    <template v-if="row.personId">{{ row.lastName }}, {{ row.firstName }}</template>
                    <template v-else>-</template>
                </td>
                <td>
                    <template v-if="row.deviceName">{{ row.deviceName }}</template>
                    <template v-else>-</template>
                </td>
                <td>
                    {{ row.temperature == 0 || row.temperature == null ? '-' : row.temperature }}
                </td>
                <td>
                    <template v-if="row.hasMask">Yes</template>
                    <template v-else>-</template>
                </td>
                <td>
                    {{ row.result | formatResult }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import RESULT_DESCRIPTIONS from '@/common/maps/scan-result';
import WQViewModal from '@/common/modals/wq-view';

export default {
    props: ['data', 'startDate', 'endDate'],

    filters: {
        formatResult(value) {
            return value ? RESULT_DESCRIPTIONS[value] : '-';
        }
    },

    methods: {
        showWQ(row) {
            this.$modal(WQViewModal, {
                wqId: row.wqId
            });
        }
    }
};
</script>

<style lang="scss" scoped>
tbody tr:hover {
    @apply cursor-pointer;
}
</style>
