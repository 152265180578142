<template>
    <page id="people">
        <div class="filters flex">
            <button class="btn-primary" v-disabled="!people" @click="addPerson"><i class="fa fa-plus" /> Add Person</button>
            <button class="btn-primary ml-1" @click="uploadPeople" v-tip="'Upload Identities & Photos'"><i class="fa fa-upload" /></button>

            <template v-if="session.isProProduct">
                <smart-select v-model="filters.groupId" class="ml-3" :options="groups" value-key="uuid" nullTitle="All Groups" />
                <button class="btn-primary ml-1" @click="manageGroups" v-tip="'Manage Groups'"><i class="fa fa-users" /></button>
            </template>

            <div class="search ml-auto">
                <input v-model="searchText" type="text" placeholder="Search" @keydown.enter="performSearch" @keydown.escape="clearSearch" />
            </div>
            <button class="btn-primary ml-2" v-disabled="!people" @click="load"><i class="fas fa-sync" /></button>
        </div>

        <table>
            <thead>
                <tr>
                    <th>Photo</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Group</th>
                    <th>Last Scan</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!people" class="info">
                    <td colspan="7">Loading...</td>
                </tr>
                <tr v-else-if="people.length == 0" class="info">
                    <td colspan="7">No people have been registered in this organization.</td>
                </tr>
                <tr
                    v-else
                    v-for="person in people"
                    :key="person.uuid"
                    class="clickable"
                    :class="{ 'is-banned': person.isBanned }"
                    @click="openPerson(person)"
                    @contextmenu.prevent="showContextMenu($event, person)"
                >
                    <td class="photo-col">
                        <div class="photo">
                            <img v-if="person.isFaceRegistered" :src="`/api/orgs/${orgId}/people/${person.uuid}/photo?v=` + person.photoVersion" />
                        </div>
                    </td>
                    <td>{{ person.customIdentifier | dash }}</td>
                    <td>{{ person.lastName }}, {{ person.firstName }}</td>
                    <td>{{ person.email | dash }}</td>
                    <td>{{ person.phone | phone | dash }}</td>
                    <td>{{ groupNamesById[person.groupId] | dash }}</td>
                    <td>
                        <template v-if="person.lastScanAt">
                            <div v-datetime="person.lastScanAt" format="M/D/YY h:mm:ss a" />
                            <div>at {{ deviceNamesById[person.lastScanDeviceId] || 'unknown device ' + person.lastScanDeviceId }}</div>
                        </template>
                        <template v-else>Never</template>
                    </td>
                </tr>
            </tbody>
        </table>
    </page>
</template>

<script>
import ViewPersonModal from './people/m-view';
import EditPersonModal from './people/m-edit';
import UploadPeopleModal from './people/m-upload';
import GroupsModal from './people/m-groups';

export default {
    store: ['session'],

    data() {
        return {
            people: null,
            searchText: null,
            filters: {
                groupId: null,
                search: null
            }
        };
    },

    watch: {
        session() {
            this.load();
        },

        filters: {
            deep: true,
            handler() {
                this.load();
            }
        }
    },

    computed: {
        orgId() {
            return this.session.activeOrg.uuid;
        },

        groupNamesById() {
            return this.session.activeOrg.groups;
        },

        deviceNamesById() {
            return this.session.activeOrg.devices;
        },

        requestParams() {
            return {
                groupId: this.filters.groupId || undefined,
                q: this.filters.search || undefined
            };
        },

        groups() {
            let arr = [];
            for (let uuid in this.groupNamesById) {
                arr.push({ uuid, name: this.groupNamesById[uuid] });
            }
            return arr;
        }
    },

    filters: {
        version(raw) {
            const matches = String(raw).match(/^([0-9]*)([0-9]{1,3})([0-9]{3})$/);
            return parseInt(matches[1] || '0') + '.' + parseInt(matches[2]) + '.' + parseInt(matches[3]);
        }
    },

    created() {
        this.$ws.addEventListener('person.update', this.handlePersonUpdate);
        this.$ws.addEventListener('scans', this.handleDeviceScan);
    },

    mounted() {
        this.loadFull();
    },

    beforeDestroy() {
        this.$ws.removeEventListener('person.update', this.handlePersonUpdate);
        this.$ws.removeEventListener('scans', this.handleDeviceScan);
    },

    methods: {
        async addPerson() {
            const groupId = this.filters.groupId || Object.keys(this.groupNamesById)[0];
            const group = { uuid: groupId, name: this.groupNamesById[groupId] };
            const newPerson = await this.$modal(EditPersonModal, { group });
            if (!newPerson) return;

            this.people.unshift(newPerson);
        },

        async uploadPeople() {
            const didComplete = await this.$modal(UploadPeopleModal);
            if (!didComplete) return;
            this.loadFull();
        },

        async manageGroups() {
            this.$modal(GroupsModal);
        },

        performSearch() {
            this.filters.search = this.searchText;
        },

        clearSearch() {
            this.searchText = null;
            this.filters.search = null;
        },

        async loadFull() {
            this.people = null;
            const response = await this.$http.get('/api/orgs/:orgId/people', {
                params: this.requestParams
            });
            this.people = response.data;
        },

        async load() {
            this.people = null;
            const response = await this.$http.get('/api/orgs/:orgId/people', {
                params: this.requestParams
            });
            this.people = response.data;
        },

        showContextMenu(e, person) {
            this.$contextMenu(e, {
                items: [
                    {
                        title: 'View',
                        handler: () => {
                            this.openPerson(person);
                        }
                    },
                    {
                        title: 'Edit',
                        handler: () => {
                            this.editPerson(person);
                        }
                    },
                    {
                        title: 'Delete',
                        handler: () => {
                            this.deletePerson(person);
                        }
                    }
                ]
            });
        },

        async openPerson(person) {
            const group = { uuid: person.groupId, name: this.groupNamesById[person.groupId] };
            this.$modal(ViewPersonModal, { person, group });
        },

        async editPerson(person) {
            const group = { uuid: person.groupId, name: this.groupNamesById[person.groupId] };
            const updatedPerson = await this.$modal(EditPersonModal, { person, group });
            if (!updatedPerson) return;

            Object.assign(person, updatedPerson);
        },

        async deletePerson(person) {
            let response = await this.$confirm('Delete Person', `Are you sure you want to delete "${person.firstName} ${person.lastName}"?`);
            if (!response) return;

            try {
                await this.$http.delete('/api/orgs/:orgId/people/' + person.uuid);
                this.people.remove(person);
            } catch (err) {
                this.$alert('Delete Person', err);
            }
        },

        async handlePersonUpdate(e) {
            const existingPerson = this.people.find(person => person.uuid === e.data.personId);
            const response = await this.$http.get('/api/orgs/:orgId/people', {
                params: {
                    id: e.data.personId
                }
            });

            if (!response.data.length) {
                existingPerson && this.people.remove(existingPerson);
            } else {
                const updatedPerson = response.data[0];
                if (existingPerson) Object.assign(existingPerson, updatedPerson);
                else this.people.unshift(updatedPerson);
            }
        },

        async handleDeviceScan(e) {
            if (!e.data.people) return;

            for (const [uuid, timestamp] of Object.entries(e.data.people)) {
                let person = this.people.find(person => person.uuid == uuid);
                if (!person) return;

                person.lastScanAt = timestamp;
                person.lastScanDeviceId = e.data.deviceId;
            }
        }
    }
};
</script>

<style lang="scss">
#people {
    .is-banned {
        color: #900;
    }

    .photo-col {
        width: 64px;

        .photo {
            @apply relative rounded-sm overflow-hidden;
            width: 48px;
            height: 48px;

            &:before {
                @apply absolute w-full h-full flex items-center justify-center text-3xl font-bold;
                background: #e2e8f0;
                color: #666;
                content: '\f007';
                font-family: 'Font Awesome 5 Pro';
            }

            img {
                @apply absolute object-cover;
                width: 48px;
                height: 48px;
                z-index: 1;
            }
        }
    }

    tbody tr {
        height: 64px;
    }
}
</style>
