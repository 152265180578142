<template>
    <div>
        <div class="filters flex">
            <button class="btn-primary" @click="addOrg"><i class="fa fa-plus" /> Add Organization</button>

            <div class="ml-auto flex">
                <div v-if="isOrgSync" class="select-wrap mr-2">
                    <select v-if="resellers" v-model="filterReseller">
                        <option :value="null">All Resellers</option>
                        <option v-for="reseller in resellers" :key="reseller.uuid" :value="reseller">{{ reseller.name }}</option>
                    </select>
                </div>

                <input v-model="search" type="text" placeholder="Search..." />
            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Product</th>
                    <th>Devices</th>
                    <th>Last Exp.</th>
                    <th>Server Ver.</th>
                    <th>Connected</th>

                    <template v-if="isOrgSync">
                        <th>Sync $$</th>
                        <th>Sync Pd</th>
                        <th>S24 Invcd</th>
                    </template>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!orgs" class="info">
                    <td colspan="20">Loading...</td>
                </tr>
                <tr v-else v-for="org in filteredOrgs" :key="org.uuid" class="clickable" @contextmenu.prevent="showContextMenu(org, $event)">
                    <td>{{ org.name | dash }}</td>
                    <td>{{ org.type | formatType }}</td>
                    <td>{{ org.product | ucfirst | dash }}</td>
                    <td>{{ org.deviceCount | dash }}</td>
                    <td>{{ org.maxLicenseExp | dash }}</td>
                    <td>{{ org.serverVersion | version | dash }}</td>
                    <td>
                        <i v-if="org.type == 'onprem'" class="connection-status" :class="{ connected: org.isConnected }" />
                        <template v-else>-</template>
                    </td>

                    <template v-if="isOrgSync">
                        <td>
                            <template v-if="org.revenue !== null">${{ org.revenue | usCurrency }}</template>
                            <template v-else>-</template>
                        </td>
                        <td>
                            <i v-if="org.hasPaidDistributor" class="fa fa-check" />
                            <template v-else>-</template>
                        </td>
                        <td>
                            <i v-if="org.hasPaidS24" class="fa fa-check" />
                            <template v-else>-</template>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import EditModal from './f-orgs/m-edit';
import LicenseModal from './f-orgs/m-license';
import ConfigModal from './f-orgs/m-config';

const TYPE_DESCRIPTIONS = {
    cloud: 'Cloud',
    onprem: 'On-Premises'
};

export default {
    store: ['session'],

    data() {
        return {
            resellers: null,
            orgs: null,
            filterReseller: null,
            search: null
        };
    },

    computed: {
        filteredOrgs() {
            if (!this.orgs) return [];
            if (!this.search) return this.orgs;
            const lcSearch = this.search.toLowerCase();
            return this.orgs.filter(org => org.name.toLowerCase().includes(lcSearch));
        },

        isOrgSync() {
            return this.session.activeOrg.uuid === '6f81a6ee-7ff6-4c8d-954b-ff8d8485b7d7';
        }
    },

    filters: {
        formatType(type) {
            return TYPE_DESCRIPTIONS[type];
        }
    },

    watch: {
        session() {
            this.resellers = null;
            this.load();
        },

        orgs() {
            if (this.resellers === null) {
                this.resellers = this.orgs.filter(org => org.isReseller);
            }
        },

        filterReseller() {
            this.load();
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            try {
                const response = await this.$http.get('/api/orgs/:orgId/sysadmin/orgs', {
                    params: { resellerUuid: this.filterReseller?.uuid || undefined }
                });
                this.orgs = response.data;
            } catch (err) {
                if (err.response?.status === 403) return this.$router.replace('/');
                throw err;
            }
        },

        async addOrg() {
            const newOrg = await this.$modal(EditModal);
            if (!newOrg) return;
            this.load();
        },

        async editOrg(org) {
            const updatedOrg = await this.$modal(EditModal, { org });
            if (!updatedOrg) return;
            this.load();
        },

        async getLicenseKey(org) {
            this.$wait();
            const response = await this.$http.get('/api/orgs/:orgId/sysadmin/orgs/' + org.uuid);
            this.$endWait();

            const license = response.data.licenseKey;
            this.$modal(LicenseModal, { org, license });
        },

        showConfigParams(org) {
            this.$modal(ConfigModal, { org });
        },

        async showContextMenu(org, e) {
            let items = [
                {
                    title: 'Edit',
                    handler: () => this.editOrg(org)
                }
            ];

            if (org.type === 'onprem') {
                items.push(
                    {
                        title: 'Get License Key',
                        handler: () => this.getLicenseKey(org)
                    },
                    {
                        title: 'Configuration Params',
                        handler: () => this.showConfigParams(org)
                    }
                );
            }

            this.$contextMenu(e, { items });
        }
    }
};
</script>

<style lang="scss" scoped>
.connection-status {
    @apply block border border-gray-500 rounded-full;
    width: 12px;
    height: 12px;

    &.connected {
        border-color: $connected-color;
        background: $connected-color;
    }
}
</style>
