<template>
    <div class="identities">
        <ol>
            <li><button class="btn-primary" @click.prevent="downloadTemplate">Click here</button> to download the template.</li>

            <li>
                Fill in the template.<br />
                Name is required.<br />
                All other fields are optional.<br /><br />
                Existing people with matching IDs will be updated.<br />
                All others will be created.<br /><br />
                Do not modify the layout of the file.
            </li>

            <li>
                <p>Select the filled out file:</p>
                <input type="file" ref="uploadFile" accept=".xlsx" @input="handleFileSelected" />
            </li>

            <li>
                <p>Select the group in which to place the imported people:</p>
                <smart-select v-model="targetGroup" :options="groups" />
            </li>

            <li>Click Upload below.</li>
        </ol>
    </div>
</template>

<script>
export default {
    store: ['session'],

    data() {
        return {
            uploadFile: null,
            targetGroup: null
        };
    },

    computed: {
        isReady() {
            return this.uploadFile !== null;
        },

        groups() {
            return Object.keys(this.session.activeOrg.groups).map(uuid => {
                return { uuid, name: this.session.activeOrg.groups[uuid] };
            });
        }
    },

    watch: {
        isReady() {
            this.$emit('ready', this.isReady);
        }
    },

    created() {
        this.targetGroup = this.groups[0];
        this.$emit('ready', false);
    },

    methods: {
        downloadTemplate() {
            window.open('/resources/syncscan-people-template.xlsx');
        },

        handleFileSelected(e) {
            this.uploadFile = e.target.files[0];
        },

        async upload() {
            let formData = new FormData();
            formData.set('file', this.uploadFile);
            formData.set('_payload', JSON.stringify({ groupUuid: this.targetGroup.uuid }));

            const response = await this.$http.post('/api/orgs/:orgId/people/upload', formData);
            await this.$alert(
                'Import People',
                `${response.data.createCount} ${response.data.createCount == 1 ? 'person' : 'people'} created.\n${response.data.updateCount} ${
                    response.data.updateCount == 1 ? 'person' : 'people'
                } updated.`
            );
        }
    }
};
</script>

<style lang="scss" scoped>
div.identities {
    @apply p-4;
    width: 350px;
}

ol {
    @apply ml-5 list-decimal;

    button {
        height: 24px;
    }
}

li:not(:first-child) {
    @apply mt-4;
}
</style>
