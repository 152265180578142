<template>
    <table>
        <thead>
            <th>Date/Time</th>
            <th>Person</th>
            <th>Group</th>
            <th>Device</th>
            <th>Temperature</th>
            <th>Mask</th>
            <th>Result</th>
        </thead>
        <tbody>
            <tr v-for="(row, index) in data" :key="index">
                <td v-datetime="row.scannedAt" format="M/D/YY h:mm:ss a" />
                <td>
                    <template v-if="row.personId">{{ row.lastName }}, {{ row.firstName }}</template>
                    <template v-else>&mdash;</template>
                </td>
                <td>
                    <template v-if="row.groupName">{{ row.groupName }}</template>
                    <template v-else>&mdash;</template>
                </td>
                <td>
                    <template v-if="row.deviceName">{{ row.deviceName }}</template>
                    <template v-else>&mdash;</template>
                </td>
                <td>
                    {{ row.temperature == 0 ? '-' : row.temperature }}
                </td>
                <td>
                    <template v-if="row.hasMask">Yes</template>
                    <template v-else>&mdash;</template>
                </td>
                <td>
                    {{ row.result | formatResult }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import RESULT_DESCRIPTIONS from '@/common/maps/scan-result';

export default {
    props: ['data', 'startDate', 'endDate'],

    filters: {
        formatResult(value) {
            return RESULT_DESCRIPTIONS[value];
        }
    }
};
</script>

<style></style>
